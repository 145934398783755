<template>
    <span>
        <div class="grille_itk">
            <div>
                <label>Date de plantation : 
                    <input type="date" name="date_plantation" v-model="localItk.date_plantation">
                </label>
                <label>Date de buttage : 
                    <input type="date" name="date_buttage" v-model="localItk.date_buttage">
                </label>        
                <label>Date de défanage prévisionnelle : 
                    <input type="date" name="date_defanage" v-model="localItk.date_defanage">
                </label>
                <label>Date de récolte prévisionnelle : 
                    <input type="date" name="date_recolte" v-model="localItk.date_recolte">
                </label>
            </div>
            <div>
                <label>Nom de la variété : 
                <select name="variete_nom" v-model="localItk.variete_nom">
                    <option v-for="une_variete in constant.itk.culture_variete" v-bind:key="une_variete.key" :value="une_variete">{{une_variete}}</option>
                </select>
                </label>
                <label>Densité de plantation : 
                    <input type="number" name="densite_plantation" step="0.01" v-model.number="localItk.densite_plantation"> tubercules/ha
                </label>
                <label style="display: block;"><span style="cursor: help;" title="La profondeur de plantation correspond à la hauteur de terre au-dessus du plant">Profondeur de plantation<span class="italic bold">*</span></span> :
                    <input type="number" name="profondeur_plantation" step="0.01" min="5" max="20" v-model.number="localItk.profondeur_plantation" oninvalid="setCustomValidity(`Profondeur de plantation : doit être comprise entre 5 et 20 cm (valeur saisie : ${this.value})`)" oninput="setCustomValidity('')"> cm
                </label> 
                <label>Calibre du plant : 
                    <input type="text" name="plant_calibre" v-model="localItk.plant_calibre" placeholder="ex. : 30/40, 30-40, 30"> mm
                </label>
                <label>Stade du plant : 
                    <select name="stade_plant" v-model="localItk.stade_plant">
                        <option value="non_germe">non_germe</option>
                        <option value="points_blancs" selected="">points_blancs</option>
                        <option value="germes_5mm">germes_5mm</option>
                    </select>
                </label>
                <label>Type de butte : 
                    <select name="type_butte" v-model="localItk.type_butte">
                        <option value="butte" selected="">butte</option>
                        <option value="billon">billon</option>
                    </select>
                </label>
            </div>        
            <!--<label>Hauteur butte : 
                <input type="number" name="hauteur_butte" step="0.01" v-model="localItk.hauteur_butte"> cm
            </label>-->
            <!-- <label>Initialisation Bilan Hydrique : {{ localItk.ini_BH }} </label> -->
            <!--<label>INN cible : 
                <input type="text" name="INN_cible" step="0.01" v-model="localItk.INN_cible">
            </label>-->
        </div>
        <span><span class="bold">*</span> <span class="italic">La profondeur de plantation correspond à la hauteur de terre au-dessus du plant</span></span>
    </span>
</template>

<script>
import { constantes } from '@/utils';

// TODO Variabiliser les intitulés de libellés
export default {
    name: 'ItkNew',
    components: {
    },
    data() {
        //const anneeEnCours = (new Date()).getFullYear();

        return {
            localItk: null,
            constant: constantes,
        }
    },
    watch: {

    },
    computed: {
        
    },
    methods: {
        addItk() {
            this.$emit("addItk", this.localItk);            
        },
        initItk() {
            this.localItk = {
                date_buttage: "",
                date_plantation: "",
                date_defanage: `${(new Date()).getFullYear()}-12-15`,
                date_recolte: `${(new Date()).getFullYear()}-12-31`,
                profondeur_plantation: 5,
                hauteur_butte: constantes.itk.hauteur_butte.value, // profondeur plantation + 10
                densite_plantation: 0,
                plant_calibre: '',
                variete_nom: 'BINTJE',
                type_butte: 'butte',
                stade_plant: 'non_germe',
                ini_BH: '01-01',
                INN_cible: null,
// TODO Mettre certaines données dans le fichier "constantes.js" ?
                stress_gel_impact: false,
                senescence_foliaire_impact: true,
                irrigation_impact: true,
                stress_hydrique_impact: true,
                croissance_racinaire_strategie: 2,
                senescence_racinaire_strategie: 2,
                epaisseur_tranche_sol: 10,
                temperature_sol_modele: 'ARVALIS',
            };
            
        }
    },
    beforeMount() {
        this.initItk();
    },
    mounted() {
        
        
    },
    filters: {
        // cela ne fonctionne pas sur un champ de saisie
        // cela fonctionne si on affiche uniquement la variable
        // <input type="text" name="nimporte" v-model.="localItk.nimporte | percentaege">
        'percentage': {
            read: function (val) {
                return val * 100;
            },
            write: function (val) { //, oldVal) {
                //const number = +val.replace(/[^\d.,]/g, '');
                //return isNaN(number) ? 0 : parseFloat(number.toFixed(2))
                return val / 100;
            }
        },
        percentage2: function (/*value*/) {
            return 20;
        },
        capitalize: function (value) {
            if (!value) return ''
            value = value.toString()
            return value.charAt(0).toUpperCase() + value.slice(1)
        },
    }
}
</script>

<style lang="scss">
    .grille_itk {
        display: grid;
        grid-template-columns: 50% 50%;
    }
    .grille_itk > div > label {
        display: block;
    }

    .italic {
        font-style: italic;
    }

    .bold {
        font-weight: bold;
    }
</style>