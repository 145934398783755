import Vue from 'vue';
import Vuex from 'vuex';
import VueKeyCloak from '@dsb-norge/vue-keycloak-js';
import {KeycloakConfig} from './config/keycloak.config';
import store from '@/store/index';
import {router} from "@/router/index";

import App from './App.vue';

import Buefy from 'buefy';
import 'buefy/dist/buefy.css';

import axios from "axios";

function tokenInterceptor () {
  axios.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${Vue.prototype.$keycloak.token}`
    return config
  }, error => {
    return Promise.reject(error)
  })
}



Vue.use(Buefy);
Vue.use(Vuex);

Vue.filter('capitalize2', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.config.productionTip = false;

Vue.use(VueKeyCloak, {
  init: {
      onLoad: 'check-sso',
      loginRequired: true,
      
        // actualise toutes les 5 secondes la page (vers url/#state=idXXXXXXXXXXXXXXX)
        //onLoad: 'login-required',
  },
  config: KeycloakConfig,
  logout: process.env.VUE_APP_SITE_URL,
  onReady: (/*kc*/) => {
    tokenInterceptor();

    //console.log(`I wonder what Keycloak returns: ${kc}`);
  //onReady: () => {
    new Vue({
      router,
      store,
      render: h => h(App),
    }).$mount('#app')
  }
});