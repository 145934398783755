<template>
    <div>
        <div>
            <div>Horizon : {{ localSol.horizon }}</div>
            <label>Épaisseur : <input type="number" v-model.number="localSol.epaisseur" /> cm</label>
            <label>Triangle :          
                {{ this.textOfDefaultValueTriangle }}<input type="hidden" :value="constantSols.triangle.defaultValue" readonly>
<!--<select v-model="localSol.triangle">
                    <option v-for="uneOption in constantSols.triangle.values" :key="uneOption.text" :value="uneOption.value">{{ uneOption.text }}</option>
                </select>-->
            </label>
            <div class="texture_ou_details">
                <div>
                    <label>{{ constantSols.texture.label }} : 
                    <select v-model="localSol.texture">
                        <option v-for="uneOption in constantSols.texture.values" :key="uneOption.text" :value="uneOption.value">{{ uneOption.text }}</option>
                    </select>
                    </label>
                </div>
                <div> ---OU--- </div>
                <div>
                    <label>{{ constantSols.argile.label }} : <input type="number" v-model.number="localSol.argile" step="0.01">{{ constantSols.argile.unite }}</label>
                    <br>
                    <label>{{ constantSols.limon.label }} : <input type="number" v-model.number="localSol.limon" step="0.01">{{ constantSols.limon.unite }}</label>
                    <br>
                    <label>{{ constantSols.sable.label }} : <input type="number" v-model.number="localSol.sable" step="0.01">{{ constantSols.sable.unite }}</label>
                    <br>
                </div>
            </div>
        </div>
        <type-sol v-for="unTypesol in typesols" :key="unTypesol.id" 
        v-bind="unTypesol"
        v-on:edit="editTypesol">
        </type-sol>
    </div>
</template>
<script>
import { constantes } from '@/utils';

const solDefault = {
    horizon: null,
    epaisseur: 0,
    triangle: (-1 != Object.keys(constantes.sols.triangle).indexOf('defaultValue')) ? constantes.sols.triangle.defaultValue : null,
    texture: constantes.sols.texture.defaultValue,
    argile: null,
    limon: null,
    sable: null,
    carbone_organique: null,
    densite_apparente: null,
    humidite_initiale: null,                
    humidite_saturation: null,
    humidite_capacite_au_champ: null,
    humidite_point_fletrissement: null,
};

export default {
    name: 'TypeSolHorizon',
    props: {
        id: {
            type: Number,
        },
        horizon: {
            type: Number,
            //default: null,
            validator: constantes.sols.horizon.validation,
        },
        triangle: {
            type: String,
            default: null,
        },
        epaisseur: {
            type: Number,
            default: 0,
        },
        texture: {
            type: String,
            default: constantes.sols.texture.defaultValue,
        },
        argile: {
            type: Number,
            default: null,
        },
        limon: {
            type: Number,
            default: null,
        },
        sable: {
            type: Number,
            default: null,
        },
    },
    components: {
        
    },
    data() {
        return {
            constantSols: constantes.sols,
            textOfDefaultValueTriangle: constantes.sols.triangle.values.filter(eltI => constantes.sols.triangle.defaultValue == eltI.value).map(eltI => eltI.text)[0],
            typesols: [],
            currentId: null,
            localSol: JSON.parse(JSON.stringify(solDefault)),
        };
    },
    mounted() {
        this.localSol.horizon = this.horizon;
        this.typesols = [
            
        ];
    },
    methods: {
        saveTypesol() {
            let idx = this.typesols.findIndex(currentTypesol => currentTypesol.id == this.currentId);

            if(-1 !== idx) {
                //console.log("Ligne de Type de sol sauvegardé", this.typesols[idx]);
                this.typesols[idx].epaisseur = this.localSol.epaisseur;
                //this.$emit("message", "info", "Ligne de Type de sol supprimée");
            }
            else {
                //this.$emit("message", "warning", "Ligne de Type de sol inexistante");
                console.warn("ligne de Type de sol inexistante (%d)", idx);
            }
        },
        editTypesol(id) {
            this.currentId = id;
            let idx = this.typesols.findIndex(currentTypesol => currentTypesol.id == this.currentId);
            
            this.localSol = this.typesols[idx];
        },
        initTypesol() {
            this.localSol = JSON.parse(JSON.stringify(solDefault));
        },
    }
}
</script>
<style lang="scss">
    .texture_ou_details {
        display: flex;
        justify-content: space-evenly;
    }
</style>