<template>
    <span>
        <label for="">{{ this.title }}
        </label>
        <div :id="uuid"></div>
    </span>
</template>

<script>
import * as c3 from 'c3/c3';
import * as d3 from 'd3';
import { generateUuid } from '@/utils';


export default {
    name: 'ChartC3JsStackedBar',
    props: {
        title: String,
        X_LEGEND: {
            type: Array,
            required: true,
        },
        data: {
            type: Array,
            required: true,
        },
        optionsData: {
            type: Object,
            required: true,
        },
        optionsAxisY: {
            type: Object,
            required: false,
        },
        x_label: {
            type: String,
        },
        y_label: {
            type: String,
        }
    },
    data: function () {
        return {
            uuid: 0,
        }
    },
    created() {
        this.uuid = "ChartC3JsStackedBar" + (generateUuid()).toString();
    },
    mounted: function() {
        let configPourGraphique = {
          //title: this.title,
          bindto: `#${this.uuid}`,
          size: {
              //width: 360,
          },
          data: {
              columns: this.data,
              //groups: [
              //  CLASSES_CALIBRE
              //],
              colors: {
                pattern: ['#ff0000', '#00a08a', '#f2ad00', '#f98400']
              },
              ...this.optionsData,
              axes: {
                  //data2: 'y2'
              },
              type: 'bar'                
          },
          axis: {
            x: {
                label: {
                    text: this.x_label, //'Date',
                    position: 'outer-center'
                },
                type: 'category',
                // la propriété "tick" prend le dessus sur la propriété "categories"
                categories: this.X_LEGEND,
                //tick: {
                //    format: function (d) {
                //        return this.X_LEGEND[d].split('-').reverse().join('/');
                //    }
                //}
            },
            y: {
                label: {
                    text: this.y_label,
                    position: 'outer-middle'
                },
                tick: {
                    //formatPrefix: d3.formatPrefix("E")
                    format: function(d) {
                        var p = d3.format('.2f')(d);
                        return p;
                    }
                },
                ...this.optionsAxisY,
            }
          }
        };

        c3.generate(configPourGraphique);
    }
    ,
    methods: {
        
    }
}

</script>

<style>

</style>