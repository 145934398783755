<template class="container">
    <span>
        <type-sol-horizon ref="typeSolHorizon1" :horizon="1"></type-sol-horizon>
        <type-sol-horizon ref="typeSolHorizon2" :horizon="2"></type-sol-horizon>
        <type-sol-horizon ref="typeSolHorizon3" :horizon="3"></type-sol-horizon>
        <div>
            <!--<b-button type="is-info is-light" @click="save">Sauvegarder</b-button>-->
        </div>
    </span>
</template>
<script>
import { constantes } from '@/utils';
import TypeSolHorizon from '@/components/TypeSolHorizon.vue';

export default {
    name: 'TypeSol',
    components: {
        TypeSolHorizon
    },
    data() {
        return {
            constant: constantes,
            isActive: true,
        }
    },
    methods: {
        save() {
            this.$emit('save');
        }
    }
}
</script>
<style lang="scss" scoped>
    .container {
        border: 1px solid #cccccc;
    }

    .label1 {
        font-weight: bold;

        &::after {
            content: " : "
        }
    }
    
</style>