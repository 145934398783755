<template>
  <div>
      

    <h1 class="title"></h1>
    <p class="description">
      Vous n'avez pas les droits suffisants
    </p>
    <b-button type="is-info is-light" label="Retour à l'accueil" icon-left="home" @click="$router.push('/');"/>
  </div>
</template>

<script>

export default {
    name: 'Error',
    components: {
        
    },
    data() {
        return {
        
        };
    },
    computed: {
        
    },
    created() {
        
    },
    beforeMount() {
    },
    mounted() {
    },
    methods: {

    }
}
</script>

<style lang="scss">

</style>