<template>
    <span>
        <label :for="uuid">{{ this.title }}
        </label>
        <div :id="uuid"></div>
    </span>
</template>

<script>
import * as c3 from 'c3/c3';
import * as d3 from 'd3';
import { generateUuid, TabDeTab } from '@/utils';

class Chartc3js {
    constructor(title, data, options, optionsData, optionsSupp = {y_label: "", optionsAxisY: {}}) {
        this.title = title;
        this.data = data;
        this.options = options;
        this.optionsData = optionsData;
        this.y_label = optionsSupp.y_label;
        this.optionsAxisY = optionsSupp.optionsAxisY;
    }

    generate() {
        this.chart = c3.generate({
            point: {
                show: false  
            },
            ...this.options,
            data: {
                x: 'x',
                columns: [
                    ...this.data        
                ],
                ...this.optionsData,  
            },
            axis: {
                x: {
                    type: 'timeseries',
                    tick: { // affiche "jj/mm" : date FR PUIS suppr heure&min PUIS suppr année
                        //format: function (x) { return x.toLocaleString("fr-FR").split(" ")[0].substring(0, 5); }
                        format: '%d/%m' // format string is also available for timeseries data
                    }
                },
                y: {
                    label: {
                        text: this.y_label,
                        position: 'outer-middle'
                    },
                    tick: {
                        //formatPrefix: d3.formatPrefix("E")
                        format: function(d) {
                            var p = d3.format('.2f')(d);
                            return p;
                        }
                    },
                    ...this.optionsAxisY
                }
            },
            legend: {
                item: {
                    tile: {
                        width: 20,
                        height: 2
                    }
                }
            }
        });
    
        return this.chart;
    }

    unloadDataGraph(donnees) {
        this.chart.unload(donnees);
    }

    loadDataGraph(donnees) {
        this.chart.load(donnees);
    }
}


// A SAVOIR : INTERESSANT graphique ligne > légende > item > rectangle de couleur : sélecteur pour pouvoir agir dessus :
// svg.g.(c3-legend-item c3-legend-item-scenarioClim2005-défanage31/08).c3-legend-item-tile

export default {
    name: 'ChartC3JsLine',
    props: {
        title: String,
        X_LEGEND: Array,
        y_label: {
            type: String,
            required: false,
        },
        data: Array,
        optionsData: {
            type: Object,
            required: false,
        },
        optionsAxisY: {
            type: Object,
            required: false,
        }
    },
    data: function () {
        return {
            uuid: 0,
            oChart: null,
            jeuDonneesInitial: null,
        }
    },
    created() {
        this.uuid = "ChartC3JsLine" + (generateUuid()).toString();
    },
    mounted: function() {   
        let varInteretI = this.title;
        const X_LEGEND = this.X_LEGEND;

//#region variables d'intérêt - configuration des graphiques
        const dataVarInteretI = this.data;

        const config = {
            title: varInteretI,
            options: {
                bindto: `#${this.uuid}`,
            },
            optionsData: this.optionsData,
            data: [
                X_LEGEND,
                ...dataVarInteretI,
            ],
            optionsAxisY: this.optionsAxisY,
        };
//#endregion variables d'intérêt - configuration des graphiques

//#region variables d'intérêt - génération des graphiques
        this.oChart = new Chartc3js(config.title, config.data, config.options, config.optionsData, {y_label: this.y_label, optionsAxisY: config.optionsAxisY});
        this.oChart.generate();
        this.jeuDonneesInitial = this.oChart.data;
        
        // définition du style des courbes dans la légende
        if(undefined != this.optionsData.classes) {
            Object.keys(this.optionsData?.classes).forEach(classeI => {
                let selectorLegendItem = `c3-legend-item-${classeI}`.replace(/[\s*]/g, () => "-");
                document.querySelector(`#${this.uuid}`).getElementsByClassName(selectorLegendItem)[0]?.classList?.add('c3-target-' + this.oChart.optionsData.classes[classeI]);
                //console.log("------");
                //console.log('selecteurOrigine : ' + selectorLegendItem);
                //console.log(document.querySelector(`#${this.uuid}`).getElementsByClassName(selectorLegendItem));
                //console.log(`classe : ${classeI}`);
                
            //#region querySelector modification d'un élément de légende
            /*
                // *********état de cette solution : abandonnée
                // *********solution adoptée : getElementsByClassName ne demande pas d'échapper ces caractères spéciaux.
                // document.querySelector : exige d'échapper les caractères spéciaux avec des slashs (cf. explication sur la doc officielle)
                // à partir du moment où on met tous ces échappements dans une variable, le sélecteur est considéré comme non valide
                // si on copie la valeur de la variable, directement dans "document.querySelector", cela fonctionne
                
                let selectorI = `g.c3-legend-item-${classeI}`;

                selectorI = selectorI.replace(/[()/]/g, m => "\\\\" + m); 

                console.log(`selecteur : ${selectorI}`);
                //console.log(d3.select(`#${this.uuid}`).select(selectorI).select('a.c3-legend-item-tile'));
                //console.log("querySelector : ", document.querySelector(`${selectorI}`));
                console.log(`bouclee : c3-legend-item-${classeI}`);
                console.log(config.optionsData.classes[classeI]);

                //d3.select(`#${this.uuid}`).select(`g.c3-legend-item-${classeI}`)//.classed("toto", true);
                d3.select(`#${this.uuid}`).select(selectorI).classed('c3-target-' + config.optionsData.classes[classeI], true);
            */
            //#region querySelector modification d'un élément de légende
            });
        }
//#endregion variables d'intérêt - génération des graphiques        
    }
    ,
    updated() {
        this.$nextTick(function () {
            
        });
    },
    beforeDestroy() {
        
    },
    methods: {
        zoomPeriode: function(dateDebutGraphique, dateFinGraphique) {
            let DATE_DEBUT = '1970-' + dateDebutGraphique;
            let DATE_FIN = '1970-' + dateFinGraphique;
        
            let tmp = this.jeuDonneesInitial[0].map((elt,indElt) => 0 < indElt && ('1970-' + elt.substring(5)) >= DATE_DEBUT && ('1970-' + elt.substring(5)) <= DATE_FIN ? indElt : undefined).filter(x => x !== undefined);
            let jeuDonneesNew = TabDeTab.filter(this.jeuDonneesInitial, (uneDonnee, iUneDonnee) => 0 == iUneDonnee || -1 != tmp.indexOf(iUneDonnee));
            
            
            this.oChart.unloadDataGraph();
            this.oChart.loadDataGraph({
                columns: jeuDonneesNew
            });

            // définition du style des courbes dans la légende
            if(undefined != this.optionsData.classes) {
                Object.keys(this.optionsData?.classes).forEach(classeI => {
                    let selectorLegendItem = `c3-legend-item-${classeI}`.replace(/[\s*]/g, () => "-");
                    document.querySelector(`#${this.uuid}`).getElementsByClassName(selectorLegendItem)[0]?.classList?.add('c3-target-' + this.oChart.optionsData.classes[classeI]);
                });
            }
        },
        reinitZoomPeriode() {
            this.oChart.unloadDataGraph();
            this.oChart.loadDataGraph({
                columns: this.jeuDonneesInitial
            });

            // définition du style des courbes dans la légende
            if(undefined != this.optionsData.classes) {
                Object.keys(this.optionsData?.classes).forEach(classeI => {
                    let selectorLegendItem = `c3-legend-item-${classeI}`.replace(/[\s*]/g, () => "-");
                    document.querySelector(`#${this.uuid}`).getElementsByClassName(selectorLegendItem)[0]?.classList?.add('c3-target-' + this.oChart.optionsData.classes[classeI]);
                });
            }
        }
    }
}

</script>

<style>
    .c3-target-small_dashed_line {
        stroke-dasharray: 1 2;
    }

    .c3-target-medium_dashed_line {
        stroke-dasharray: 5 3;
    }

    .c3-target-large_dashed_line {
        stroke-dasharray: 10 6;
    }

    .c3-target-solid_line {
    
    }
</style>