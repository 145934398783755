<template>
    <div>
        <h1 class="title is-2">Préparation de la simulation</h1>
        <h2 class="title is-3">Choix de la fonctionnalité</h2>
        <input
            type="radio"
            name="saisieUtilisateur.choixFct1"
            v-model.number="saisieUtilisateur.choixFct"
            value="1"
        />
        Fonctionnalité 1 (date de défanage idéale)
        <input
            type="radio"
            name="saisieUtilisateur.choixFct2"
            v-model.number="saisieUtilisateur.choixFct"
            value="2"
        />Fonctionnalité 2 (impact d'une prochaine irrigation)

        <div v-show="2 === saisieUtilisateur.choixFct">
        Dans cette fonctionnalité, la pluviométrie est prise égale à 0 dans les données Agri4Cast utilisées pour compléter le fichier météo de l'année en cours à partir de de la date de la prochaine irrigation. <br/>
        L'affichage des résultats est limité à 15 jours après la date de la prochaine irrigation.
        </div>

        <h2 class="title is-3">Parcelle</h2>

        <b-select v-model="saisieUtilisateur.idParcelle" @input="changeParcelle">
            <option
                v-for="parcelle in this.donneesUtilisateur.parcelles"
                :key="parcelle.id"
                :value="parcelle.id"
            >
                {{ parcelle.nom }}
            </option>
        </b-select>
        <b-button type="is-info is-light"><router-link to="/parcelles">Gérer mes parcelles</router-link></b-button>
    <div>
        <b-table :data="this.interne.parcelleSelectionne.data" :columns="this.interne.parcelleSelectionne.columns"></b-table>
    </div>

        <h2 class="title is-3">Fichier météo de l'année en cours</h2>
        <div>
            <p class="texte_descriptif_resultat_app">
                Les colonnes "date", "T_air_min", "T_air_max" et "pluie" doivent obligatoirement être renseignées à partir du 1er janvier.<br>
                Les irrigations déjà réalisées doivent être indiquées dans la colonne "irrigation".<br>
                Le remplissage des colonnes "R_inc" et "ET_0" est facultatif. Elles ne doivent être supprimées.<br>
            </p>
            <div>
                <a href="template_fichier_meteo.csv" download>Télécharger le template du fichier météo</a>
            </div>
            <b-field class="file is-info is-light" :class="{'has-name': !!file}">
                <b-upload 
                    v-model="saisieUtilisateur.meteo_temps_reel.fichier"
                    class="file-label"
                    rounded
                >
                    <span class="file-cta">
                        <b-icon class="file-icon" icon="upload"></b-icon>
                        <span class="file-label">{{
                            saisieUtilisateur.meteo_temps_reel.fichier
                                ? saisieUtilisateur.meteo_temps_reel.fichier
                                        .name
                                : "Fichier météo à téléverser"
                        }}</span>
                    </span>
                </b-upload>
            </b-field>
        </div>
        
        <div>
            <h2 class="title is-3">Choix des années météo de fin de cycle - BDD Agri4Cast</h2>
            <p>Ces années météo passées permettent de compléter le fichier météo de l'année en cours jusqu'à la date de récolte prévisionnelle.</p>
            <p>A partir de six années météo sélectionnées, les résultats sont présentés sous forme fréquentielle (médiane et déciles).</p>
            <br/>
            <div>
                <label for="">
                    année
                    <b-select v-model="saisieUtilisateur.meteo_scenario.annee">
                        <option
                            v-for="anneeI in this.interne.meteo.scenario.annees"
                            :key="anneeI"
                            :value="anneeI"
                        >
                            {{ anneeI }}
                        </option>
                    </b-select>
                </label>
                <b-button type="is-info is-light" @click="addScenarioClimRef()">Ajouter</b-button>
                <div
                    v-for="scenarioClimI in saisieUtilisateur.meteo_scenario.ref"
                    :key="scenarioClimI.annee"
                >
                    {{ scenarioClimI.annee }}
                    <b-button type="is-info is-light"
                        @click="
                            removeScenarioClimRef(
                                scenarioClimI.annee
                            )
                        "
                        >
                        Supprimer
                    </b-button>
                </div>
            </div>
        </div>

        <div>
            <h2 class="title is-3">Irrigation</h2>
            <div>
                <input
                    type="radio"
                    name="fct1.configUtilisateur.option_irrigation"
                    v-model.number="interFcts.utilisateur.optionIrrigation"
                    value="1"
                />
                option 1 (dates et doses fournies dans le fichier météo de l'année en cours)
            </div>
			<div v-show="1 === saisieUtilisateur.choixFct">
				<input
					type="radio"
					name="fct1.configUtilisateur.option_irrigation"
					v-model.number="interFcts.utilisateur.optionIrrigation"
					value="2"
				/> option 2 (Eau non limitante : fonctionnement du modèle de décision d'irrigation)
			</div>
			<div v-show="1 === saisieUtilisateur.choixFct">
				<input
					type="radio"
					name="fct1.configUtilisateur.option_irrigation"
					v-model.number="interFcts.utilisateur.optionIrrigation"
					value="3"
				/> option 3 (Pas d'irrigation)
			</div>
        </div>

		<div v-show="'avant on affichait cette div quand c était option2 mais plus maintenant !!!' === interFcts.utilisateur.optionIrrigation">
			<h2 class="subtitle">Modèle de décision d'irrigation</h2>
			<irrigation-modele-decision
				ref="refIrrigation"
			></irrigation-modele-decision>
		</div>

        <div>
            <div v-show="1 === saisieUtilisateur.choixFct">
                <h2 class="title is-3">
                Date de défanage prévisionnelle à étudier
                </h2>
                <div>
                    <label>
                        <input
                            type="radio"
                            name="fct1_option_defanage"
                            v-model="fct1.configUtilisateur.optionDefanage"
                            value="date_defanage_parcelle"
                        />Date de défanage prévisionnelle de l'ITK de la parcelle
                    </label>                    
                </div>
            </div>

			<div v-show="2 === saisieUtilisateur.choixFct">
                <h2 class="title is-3">Date et dose de la prochaine irrigation</h2>
				<label>
					Date : 
					<input 
						type="date" 
						name="fct2_date_prochaine_irrigation" 
						v-model="fct2.utilisateur.prochaineIrrigation.date" 
					>
				</label>
				<label>
					Dose : 
					<input 
						type="number" 
						name="fct2_dose_prochaine_irrigation" 
						v-model.number="fct2.utilisateur.prochaineIrrigation.dose" 
					> mm
				</label>
			</div>

            <h2 class="title is-3">Variables d'intérêt à analyser</h2>
            <a href="QUALTEC_Methode_de_comptage.pdf" download>Télécharger la méthode de prélèvement de référence du projet QUALTEC Pommes de terre</a>
            <div
                v-for="varInteret in this.constant.variables_interet_principale.values"
                :key="varInteret.value"
            >
                <input
                    type="checkbox"
                    name="variable_interet"
                    :value="varInteret.value"
                    v-model="interFcts.utilisateur.variablesInteretChecked"
                />
                {{ varInteret.text }}
            </div>

            <select
                v-model="interFcts.utilisateur.variablesInteretAutreChecked"
                multiple
            >
                <option
                    v-for="autres_var_interet in this.constant.variables_interet_supplementaire.values"
                    :key="autres_var_interet.value"
                    :value="autres_var_interet.value"
                >
                    {{ autres_var_interet.text }}
                </option>
            </select>
            <div>
                <h2 class="title is-3">
                    Rendement par classes de calibres
                </h2>
                <p>Les calibres renseignés doivent être multiples de 5</p>
                <div
                    v-for="variableInteretCalculee in interFcts.utilisateur.variablesInteretCalculee"
                    :key="variableInteretCalculee.id"
                >
                    Rendement pour la classe de calibre :
                    <input
                        type="number"
                        name=""
                        v-model.number="variableInteretCalculee.min"
                        step="5"
                        min="15"
                    />mm à
                    <input
                        type="number"
                        v-model.number="variableInteretCalculee.max"
                        min="15"
                        max="500"
                        step="5"
                    />mm
                    <b-button type="is-info is-light"
                        @click="removeVarInteretCalculee(variableInteretCalculee.id)"
                    >
                        Supprimer
                    </b-button>
                </div>
                <b-button type="is-info is-light" @click="addVarInteretCalculee">Ajouter</b-button>
            </div>
        </div>

        <br />

		<div v-show="1 === saisieUtilisateur.choixFct">
			<h2 class="title is-3">
				Lancement des simulations et génération des résultats
			</h2>
            
			<b-button v-if="this.$router.app.$keycloak.hasResourceRole('admin')" type="is-info is-light" @click="fct1RemplissageDonneesUtilisateur">
				Remplissage de données tests dans les champs utilisateur
			</b-button>
			<br />
            <b-button type="is-danger is-light" @click="fct1DonneesUtilisateurToResultatsSims">
				Lancement simulations et résultats
			</b-button>
            
			{{ fct1.interne.texteChargementCalculs }}
			
			<fieldset>
				<!--<div>					
                    <h2>Export des données brutes du modèle de culture</h2>
					<div>
						<b-button type="is-info is-light" @click="fct1ExportCsvResultatsLogCulture">Export csv log culture</b-button>
						<b-button type="is-info is-light" @click="fct1ExportCsvResultatsLogCalibres">Export csv log calibres</b-button>
						<b-button type="is-info is-light" @click="fct1ExportCsvResultatsLogPhases">Export csv log phases</b-button>
					</div>
				</div>-->
                <fieldset>
					<h2 class="title is-2">Diagnostic de la situation réelle</h2>
					<p class="texte_descriptif_resultat_app">
						Confrontation de la situation de l'année en cours avec celles des années passées.
					</p>
					<div>
						Date de début :
						<input
							type="text"
							v-model="fct1.interne.resultat_etat_des_lieux.debutPeriodeZoomGraphique"
							placeholder="jj/mm"
							pattern="(([01][1-9]|10|2[0-8])\/(0[1-9]|1[0-2]))|((29|30)\/(0[13-9]|1[0-2]))|(31\/(0[13578]|1[02]))"
						/>
						Date de fin :
						<input
							type="text"
							v-model="fct1.interne.resultat_etat_des_lieux.finPeriodeZoomGraphique"
							placeholder="jj/mm"
							pattern="(([01][1-9]|10|2[0-8])\/(0[1-9]|1[0-2]))|((29|30)\/(0[13-9]|1[0-2]))|(31\/(0[13578]|1[02]))"
						/>
						<b-button type="is-info is-light"
							@click="
								Array.isArray(
									$refs.chartc3jslineFct1Result3VarInteret
								)
									? zoomPeriode(
										$refs.chartc3jslineFct1Result3VarInteret,
										fct1.interne.resultat_etat_des_lieux.debutPeriodeZoomGraphique,
										fct1.interne.resultat_etat_des_lieux.finPeriodeZoomGraphique
									)
									: null;
								Array.isArray(
									$refs.chartc3jslineFct1Result3VarInteretCalc
								)
									? zoomPeriode(
										$refs.chartc3jslineFct1Result3VarInteretCalc,
										fct1.interne.resultat_etat_des_lieux.debutPeriodeZoomGraphique,
										fct1.interne.resultat_etat_des_lieux.finPeriodeZoomGraphique
									)
									: null;
							"
						>
							Zoom sur l'intervalle de date indiquée
						</b-button>
						<b-button type="is-info is-light"
							@click="
								Array.isArray(
									$refs.chartc3jslineFct1Result3VarInteret
								)
									? reinitZoomPeriode(
										$refs.chartc3jslineFct1Result3VarInteret
									)
									: null;
								Array.isArray(
									$refs.chartc3jslineFct1Result3VarInteretCalc
								)
									? reinitZoomPeriode(
										$refs.chartc3jslineFct1Result3VarInteretCalc
									)
									: null;
							"
						>
							Réinitialisation du zoom
						</b-button>
					</div>

					<!--<div>Variables d'intérêt</div>-->
					<div id="zone-charts-fct1-resultat_etat_des_lieux">
						<ChartC3JsLine
							v-for="chartI in fct1.interne.resultat_etat_des_lieux.chartsVarInteret"
							:key="chartI.iduniquepourvuejs"
							ref="chartc3jslineFct1Result3VarInteret"
							:X_LEGEND="chartI.X_LEGEND" 
                            :y_label="chartI.title" 
							:data="chartI.data"
							:optionsData="chartI.optionsData"
							>un chart</ChartC3JsLine
						>
					</div>
					<!--<div>Variables d'intérêt calculées (issu de calibres)</div>-->
					<div id="zone-charts-fct1-resultat_etat_des_lieuxcalc">
						<ChartC3JsLine
							v-for="chartI in fct1.interne.resultat_etat_des_lieux.chartsVarInteretCalc"
							:key="chartI.iduniquepourvuejs"
							ref="chartc3jslineFct1Result3VarInteretCalc"
							:X_LEGEND="chartI.X_LEGEND" 
                            :y_label="chartI.title"
							:data="chartI.data"
							:optionsData="chartI.optionsData"
							>un chart calc</ChartC3JsLine
						>
					</div>
				</fieldset>
                <h1 class="title is-2">Pronostic</h1>
				<fieldset>
					<div>
                        <p class="texte_descriptif_resultat_app">
                            Le fichier météo de l'année en cours est complété avec les données météo des années passées à partir de sa date de fin.
                        </p>
						<h2 class="title is-3">
							Impacts des dates de défanage sur les variables
							d'intérêt - Statistiques descriptives
						</h2>
						<p class="texte_descriptif_resultat_app">
							<!--Si le nombre de scénario météo sélectionné est
							inférieur à 5 alors la médiane, le 2ème et 8ème décile
							ne seront pas calculés.-->
						</p>
                        <b-table :data="fct1.interne.resultat1.resultatsTabImpactsDateDefanage.data" :columns="fct1.interne.resultat1.resultatsTabImpactsDateDefanage.columns"></b-table>                        
						<!--<span v-html="fct1.interne.resultat1.resultatsTab"></span>-->

						<h2 class="title is-3">
							Résultats des simulations - Valeurs obtenues à la récolte
						</h2>
						<b-table :data="fct1.interne.resultat1.resultatsTabSynthetique.data" :columns="fct1.interne.resultat1.resultatsTabSynthetique.columns"></b-table>
                    </div>
				</fieldset>

				<fieldset>
					<h2 class="title is-3">
						Évolution des variables d'intérêt dans le temps
					</h2>
					<div>
						Date de début :
						<input
							type="text"
							v-model="fct1.interne.resultat_evol_variables.debutPeriodeZoomGraphique"
							placeholder="jj/mm"
							pattern="(([01][1-9]|10|2[0-8])\/(0[1-9]|1[0-2]))|((29|30)\/(0[13-9]|1[0-2]))|(31\/(0[13578]|1[02]))"
						/>
						Date de fin :
						<input
							type="text"
							v-model="fct1.interne.finPeriodeZoomGraphique"
							placeholder="jj/mm"
							pattern="(([01][1-9]|10|2[0-8])\/(0[1-9]|1[0-2]))|((29|30)\/(0[13-9]|1[0-2]))|(31\/(0[13578]|1[02]))"
						/>
						<b-button type="is-info is-light"
							@click="
								Array.isArray(
									$refs.chartc3jslineFct1Result2VarInteret
								)
									? zoomPeriode(
										$refs.chartc3jslineFct1Result2VarInteret,
										fct1.interne.resultat_evol_variables.debutPeriodeZoomGraphique,
										fct1.interne.finPeriodeZoomGraphique
									)
									: null;
								Array.isArray(
									$refs.chartc3jslineFct1Result2VarInteretCalc
								)
									? zoomPeriode(
										$refs.chartc3jslineFct1Result2VarInteretCalc,
										fct1.interne.resultat_evol_variables.debutPeriodeZoomGraphique,
										fct1.interne.finPeriodeZoomGraphique
									)
									: null;
                                Array.isArray(
									$refs.chartc3jslineFct1Result2VarInteretCalcMapp
								)
									? zoomPeriode(
										$refs.chartc3jslineFct1Result2VarInteretCalcMapp,
										fct1.interne.resultat_evol_variables.debutPeriodeZoomGraphique,
										fct1.interne.finPeriodeZoomGraphique
									)
									: null;
							"
						>
							Zoom sur l'intervalle de date indiquée
						</b-button>
						<b-button type="is-info is-light"
							@click="
								Array.isArray(
									$refs.chartc3jslineFct1Result2VarInteret
								)
									? reinitZoomPeriode(
										$refs.chartc3jslineFct1Result2VarInteret
									)
									: null;
								Array.isArray(
									$refs.chartc3jslineFct1Result2VarInteretCalc
								)
									? reinitZoomPeriode(
										$refs.chartc3jslineFct1Result2VarInteretCalc
									)
									: null;
                                Array.isArray(
									$refs.chartc3jslineFct1Result2VarInteretCalcMapp
								)
									? reinitZoomPeriode(
										$refs.chartc3jslineFct1Result2VarInteretCalcMapp
									)
									: null;
							"
						>
							Réinitialisation du zoom
						</b-button>
					</div>

					<!--<div>Variables d'intérêt</div>-->
					<div id="zone-charts-fct1-resultat2">
						<ChartC3JsLine
							v-for="chartI in fct1.interne.resultat_evol_variables.chartsVarInteret"
							:key="chartI.iduniquepourvuejs"
							ref="chartc3jslineFct1Result2VarInteret"
							:X_LEGEND="chartI.X_LEGEND" 
							:data="chartI.data" 
                            :y_label="chartI.title" 
							:optionsData="chartI.optionsData"
							>un chart</ChartC3JsLine
						>
					</div>

					<!--<div>Variables d'intérêt calculées (issu de calibres)</div>-->
					<div id="zone-charts-fct1-resultat2calc">
						<ChartC3JsLine
							v-for="chartI in fct1.interne.resultat_evol_variables.chartsVarInteretCalc"
							:key="chartI.iduniquepourvuejs"
							ref="chartc3jslineFct1Result2VarInteretCalc"
							:X_LEGEND="chartI.X_LEGEND"
                            :y_label="chartI.title" 
							:data="chartI.data"
							:optionsData="chartI.optionsData"
							>un chart</ChartC3JsLine
						>
					</div>
				</fieldset>

				<fieldset>
                    <h2 class="title is-3">Évolution de la répartition des calibres</h2>
                    <!--<div>Variables d'intérêt calculées (issu de calibres)</div>-->
					<div id="zone-charts-fct1-resultat2calcMapp">
						<ChartC3JsLine
							v-for="chartI in fct1.interne.resultat_evol_variables.chartsVarInteretMapp"
							:key="chartI.iduniquepourvuejs"
							ref="chartc3jslineFct1Result2VarInteretCalcMapp"
							:title="chartI.title"
							:X_LEGEND="chartI.X_LEGEND" 
                            :y_label="'Rendement des tubercules (t.ha-1)'"
							:data="chartI.data"
							:optionsData="chartI.optionsData" 
                            :optionsAxisY="chartI.optionsAxisY" 
							>un chart</ChartC3JsLine
						>
					</div>
					<p class="texte_descriptif_resultat_app">
                        La répartition des calibres est affichée pour les différentes dates choisies par l'utilisateur.
					</p>
					<p class="texte_descriptif_resultat_app important">
                        Les dates doivent être saisies dans l'ordre chronologique (date 1 &lt; date 2 &lt; date 3).
					</p>
					<span
						v-for="(dateI, indDateI) in this.fct1.interne.resultat_evol_calibres.dates"
						:key="'fct1_resultat_evol_calibresDate' + indDateI"
						>Date {{ indDateI + 1 }} :
						<input
							type="text"
							v-model="fct1.interne.resultat_evol_calibres.dates[indDateI]"
							placeholder="JJ/MM"
                        />
                    </span>
					<!--<div
						v-for="(intervCalibreI, indIntervCalibreI) in this.fct1.interne.resultat_evol_calibres.intervsCalibre"
						:key="'fct1_resultat_evol_calibresIntervCalibre' + indIntervCalibreI + 1"
					>
						<span v-if="0 === indIntervCalibreI">
							Calibre {{ indIntervCalibreI + 1 }}
							<input
								type="number"
								v-model.number="intervCalibreI[0]"
								readonly
							/>
							à
							<input
								type="number"
								v-model.number="intervCalibreI[1]"
								step="5"
								min="15"
							/>mm
						</span>
						<span v-else-if="[1, 2].includes(indIntervCalibreI)">
							Calibre {{ indIntervCalibreI + 1 }}
							<input type="number" v-model.number="intervCalibreI[0]"
							/>
							à
							<input
								type="number"
								v-model.number="intervCalibreI[1]"
								step="5"
								min="15"
							/>mm
						</span>
						<span v-else-if="3 === indIntervCalibreI">
							Calibre {{ indIntervCalibreI + 1 }} >
							<input type="number" v-model.number="intervCalibreI[0]" />mm
							<input
								type="hidden"
								v-model.number="intervCalibreI[1]"
								step="5"
								min="15"
								readonly
							/>
						</span>
					</div>
                    <div>
					</div>-->
                    <b-button type="is-info is-light" @click="fct1ReactualiserResultatsEvolCalibres">Actualiser</b-button>
                    <br>
					<ChartC3JsStackedBar
						v-for="(chartI, indFct1ResultatChart) in fct1.interne.resultat_evol_calibres.charts"
						:key="indFct1ResultatChart + Date.now()"
						:data="chartI.data" 
                        :X_LEGEND="chartI.X_LEGEND"
						:optionsData="chartI.optionsData" 
                        :optionsAxisY="chartI.optionsAxisY"
						:title="'Date ' + (indFct1ResultatChart+1) + ' : ' + 
							fct1.interne.resultats.resultat_evol_calibres.mois_j_selected[
								indFct1ResultatChart
							].split('-').reverse().join('/')
						" 
                        
                        :x_label="fct1.interne.resultats.resultat_evol_calibres.mois_j_selected[
								indFct1ResultatChart
							].split('-').reverse().join('/')" 
                        :y_label="constant.variables_interet_principale.values.filter(elt => 'tubercules_matiere_fraiche' == elt.value).map(elt => elt.text)[0]" 
						>.
                        </ChartC3JsStackedBar>
				</fieldset>
			</fieldset>
		</div>

		<div v-show="2 === saisieUtilisateur.choixFct">
			<fieldset>
				<h2 class="title is-3">
					Lancement des simulations et génération des résultats
				</h2>
                <b-button v-if="this.$router.app.$keycloak.hasResourceRole('admin')" type="is-info is-light" @click="fct2RemplissageDonneesUtilisateur">
					Remplissage de données tests dans les champs utilisateur
				</b-button>
				<br />
				<b-button type="is-danger is-light" @click="fct2DonneesUtilisateurToResultatsSims">
					Lancement simulations et résultats
				</b-button>
				{{ fct2.interne.texteChargementCalculs }}
                <!--<div>
					<div>
						<b-button type="is-info is-light" @click="fct2ExportCsvResultatsLogCulture">
							Export csv log culture
						</b-button>
						<b-button type="is-info is-light" @click="fct2ExportCsvResultatsLogCalibres">
							Export csv log calibres
						</b-button>
						<b-button type="is-info is-light" @click="fct2ExportCsvResultatsLogPhases">
							Export csv log phases
						</b-button>
					</div>
				</div>-->
                <h1 class="title is-2">Pronostic</h1>
                <fieldset>
					<div>
                        <p class="texte_descriptif_resultat_app">
                            Le fichier météo de l'année en cours est complété avec les données météo des années passées à partir de sa date de fin.
                        </p>
						<h2 class="title is-3">Résultats des simulations</h2>
						<div class="subtitle">Valeurs obtenues 15 jours après la date de la prochaine irrigation</div>
						<b-table :data="fct2.interne.resultat1.resultatsTab.data" :columns="fct2.interne.resultat1.resultatsTab.columns"></b-table>

						<h2 class="title is-3">
							Gain simulé avec la prise en compte d'une prochaine irrigation
						</h2>
						<div class="subtitle">Synthèse des valeurs obtenues 15 jours après la date de la prochaine irrigation</div>
						<b-table :data="fct2.interne.resultat1.resultatsTabSynthetique.data" :columns="fct2.interne.resultat1.resultatsTabSynthetique.columns"></b-table>
					</div>
				</fieldset>
                <fieldset>
					<h2 class="title is-3">
						Évolution des variables d'intérêt dans le temps
					</h2>
					<div>
						Date de début :
						<input
							type="text"
							v-model="fct2.interne.resultat_evol_variables.debutPeriodeZoomGraphique"
							placeholder="jj/mm"
							pattern="(([01][1-9]|10|2[0-8])\/(0[1-9]|1[0-2]))|((29|30)\/(0[13-9]|1[0-2]))|(31\/(0[13578]|1[02]))"
						/>
						Date de fin :
						<input
							type="text"
							v-model="fct2.interne.resultat_evol_variables.finPeriodeZoomGraphique"
							placeholder="jj/mm"
							pattern="(([01][1-9]|10|2[0-8])\/(0[1-9]|1[0-2]))|((29|30)\/(0[13-9]|1[0-2]))|(31\/(0[13578]|1[02]))"
						/>
						<b-button type="is-info is-light"
							@click="
								Array.isArray(
									$refs.chartc3jslineFct2Result2VarInteret
								)
									? zoomPeriode(
										$refs.chartc3jslineFct2Result2VarInteret,
										fct2.interne.resultat_evol_variables.debutPeriodeZoomGraphique,
										fct2.interne.resultat_evol_variables.finPeriodeZoomGraphique
									)
									: null;
								Array.isArray(
									$refs.chartc3jslineFct2Result2VarInteretCalc
								)
									? zoomPeriode(
										$refs.chartc3jslineFct2Result2VarInteretCalc,
										fct2.interne.resultat_evol_variables.debutPeriodeZoomGraphique,
										fct2.interne.resultat_evol_variables.finPeriodeZoomGraphique
									)
									: null;
                                Array.isArray(
									$refs.chartc3jslineFct2Result2VarInteretCalcMapp
								)
									? zoomPeriode(
										$refs.chartc3jslineFct2Result2VarInteretCalcMapp,
										fct2.interne.resultat_evol_variables.debutPeriodeZoomGraphique,
										fct2.interne.resultat_evol_variables.finPeriodeZoomGraphique
									)
									: null;
							"
						>
							Zoom sur l'intervalle de date indiquée
						</b-button>
						<b-button type="is-info is-light"
							@click="
								Array.isArray(
									$refs.chartc3jslineFct2Result2VarInteret
								)
									? reinitZoomPeriode(
										$refs.chartc3jslineFct2Result2VarInteret
									)
									: null;
								Array.isArray(
									$refs.chartc3jslineFct2Result2VarInteretCalc
								)
									? reinitZoomPeriode(
										$refs.chartc3jslineFct2Result2VarInteretCalc
									)
									: null;
                                Array.isArray(
									$refs.chartc3jslineFct2Result2VarInteretCalcMapp
								)
									? reinitZoomPeriode(
										$refs.chartc3jslineFct2Result2VarInteretCalcMapp
									)
									: null;
							"
						>
							Réinitialisation du zoom
						</b-button>
					</div>

					<!--<div>Variables d'intérêt</div>-->
					<div id="zone-charts-fct2-resultat2">
						<ChartC3JsLine
							v-for="chartI in fct2.interne.resultat_evol_variables.chartsVarInteret"
							:key="chartI.iduniquepourvuejs"
							ref="chartc3jslineFct2Result2VarInteret"
							:X_LEGEND="chartI.X_LEGEND" 
                            :y_label="chartI.title" 
							:data="chartI.data"
							:optionsData="chartI.optionsData"
							>un chart
                        </ChartC3JsLine>
					</div>

					<!--<div>Variables d'intérêt calculées (issu de calibres)</div>-->
					<div id="zone-charts-fct2-resultat2calc">
						<ChartC3JsLine
							v-for="chartI in fct2.interne.resultat_evol_variables.chartsVarInteretCalc"
							:key="chartI.iduniquepourvuejs"
							ref="chartc3jslineFct2Result2VarInteretCalc"
							:X_LEGEND="chartI.X_LEGEND" 
                            :y_label="chartI.title" 
							:data="chartI.data"
							:optionsData="chartI.optionsData"
							>un chart
                        </ChartC3JsLine>
					</div>
				</fieldset>
			</fieldset>
		</div> 
  </div>
</template>

<script>
//import router from "@/router";

import axios from "axios";
//import * as c3 from 'c3/c3'; // fonctionne
//import c3 from "c3";
import { constantes, helperCalibre } from "@/utils"; // exportCsv
import IrrigationModeleDecision from "@/components/IrrigationModeleDecision.vue";

import ChartC3JsStackedBar from "@/components/ChartC3JsStackedBar.vue";
import ChartC3JsLine from "@/components/ChartC3JsLine";

export default {
  name: 'Simulation',
  components: {
    IrrigationModeleDecision,
    ChartC3JsStackedBar,
    ChartC3JsLine,
  },
    data() {
    return {
        constant: constantes,
        donneesUtilisateur: {
            parcelles: [],
        },
        interne: {
            refCommunes: [],
            meteo: {
                scenario: {
                    annees: constantes.scenarios_meteo.agri4cast.annees,
                },
            },
            parcelleSelectionne: {
                data: [],
                columns: [{
                        field: 'label',
                        label: 'Champ',
                    },
                    {
                        field: 'value',
                        label: 'Valeur',
                    }],
            },
            convertUniteDataSaisieToCalcul: {
                config: {
                    irrigation: {
                        phase: {
                        
                        },
                        irrigation: {
                            efficience_irrigation: val => val / 100,
                        }    
                    }
                }
            },
        },
        saisieUtilisateur: {
            // les données générales saisies, non propres à la fct1, mais indispensables pour la fct1 et les autres apps
            choixFct: 1,
            idParcelle: null,
            meteo_temps_reel: {
                fichier: null,
                jsonFromFichier: null,
                code_dept: null,
                ville: null,
            },
            meteo_scenario: {
                annee: null,
                ref: [], // tableau d'objet, contenant les références des scenarios météo à mobiliser
            },
        },
        fichieroutput: ["log_culture", "log_calibres", "phases"],
        // pour upload multiple : prop :multiple="true" et variable "file": []
        file: null,
        file2: null,

        interFcts: {
            utilisateur: {
                optionIrrigation: 3,

                variablesInteretChecked: [],
                variablesInteretAutreChecked: [],
                variablesInteretCalculee: [],
            },
            interne: {
                DEFAULT_COLORS_LINE_CHART: [
                    "#ff0000",
                    "#00ff00",
                    "#0000ff",
                    "#770077",
                    "#cccccc",
                ],
                DEFAULT_STYLES_LINE_CHART: [
                    "small_dashed_line",
                    "medium_dashed_line",
                    "large_dashed_line",
                    "solid_line",
                ],
            }
        },
        fct1: {
            configUtilisateur: {
                optionDefanage: 'date_defanage_parcelle', // plage_date (deprecated) | date_defanage_parcelle
                dateDefanageItkParcelle: null,
            },
            interne: {
                /**
                 * Les résultats stockés après retour de l'API
                 * @type {Object}
                 */
                resultats: null,
                texteChargementCalculs: "",

                resultat1: {
                    VARS_INTERET_EXCLUDED: [
                        ...constantes.variables_interet_principale.values,
                        ...constantes.variables_interet_supplementaire.values,
                    ]
                        .filter((eltI) => false === eltI.fct1resultat1)
                        .map((eltI) => eltI.value),
                    resultatsTabImpactsDateDefanage: 
                    {
                        data: 
                        [                               
                        ],
                        columns: [
                            {
                                field: 'label',
                                label: 'Variable d\'intérêt',
                            },
                            {
                                field: 'date_defanage',
                                label: 'Date de défanage',
                            },
                            {
                                field: 'moyenne',
                                label: 'Moyenne',
                                //width: '40',
                                numeric: true,
                            },
                            {
                                field: 'mediane',
                                label: 'Médiane',
                                numeric: true,
                            },
                            {
                                field: 'min',
                                label: 'Min',
                                numeric: true,
                            },
                            {
                                field: 'decile_2',
                                label: '2nd décile',
                                numeric: true,
                            },
                            {
                                field: 'decile_8',
                                label: '8ème décile',
                                numeric: true,
                            },
                            {
                                field: 'max',
                                label: 'Max',
                                numeric: true,
                            },
                            {
                                field: 'ecart_type',
                                label: 'Écart-type',
                                numeric: true,
                            },
                        ],           
                    },
                    resultatsTabSynthetique: 
                    {
                        data: 
                        [
                        ],
                        columns: [
                            
                        ]    
                    },
                },
                resultat_evol_variables: {
                    chartsVarInteret: [],
                    chartsVarInteretCalc: [],
                    chartsVarInteretMapp: [],
                    debutPeriodeZoomGraphique: null,
                    finPeriodeZoomGraphique: null,
                },
                resultat_etat_des_lieux: {
                    chartsVarInteret: [],
                    chartsVarInteretCalc: [],
                    debutPeriodeZoomGraphique: null,
                    finPeriodeZoomGraphique: null,
                },
                resultat_evol_calibres: {
                    charts: [],
                    dates: ["01/07", "15/07", "01/08"],
                    intervsCalibre: [],/*[
                        [15, 30],
                        [30, 40],
                        [40, 70],
                        [70, 500],
                    ],*/
                },
            },
        },
        fct2: {
            interne: {
                /**
                 * Les résultats stockés après retour de l'API
                 * @type {Object}
                 */
                resultats: null,
                resultat1: 
                {
                    VARS_INTERET_EXCLUDED: 
                    [
                        ...constantes.variables_interet_principale.values,
                        ...constantes.variables_interet_supplementaire.values,
                    ]
                        .filter((eltI) => false === eltI.fct2resultat1)
                        .map((eltI) => eltI.value),
                    resultatsTab: 
                    {
                        data: 
                        [                               
                        ],
                        columns: [
                            {
                                field: 'label',
                                label: 'Variable d\'intérêt sélectionnée',
                            },
                            {
                                field: 'annee',
                                label: 'Année',
                                //width: '40',
                                //numeric: true
                            },
                            {
                                field: 'valeur_sans_prochaine_irrig',
                                label: 'Valeur sans prochaine irrigation',
                                numeric: true,
                            },
                            {
                                field: 'valeur_avec_prochaine_irrig',
                                label: 'Valeur avec prochaine irrigation',
                                numeric: true,
                            },
                            {
                                field: 'coeff_gain_avec_prochaine_irrig',
                                label: '% de différence avec une prochaine irrigation',
                                numeric: true,
                            },
                            // {
                            //     field: 'rdt_sans_prochaine_irrig__div__qte_eau',
                            //     label: 'Rendement sans prochaine irrigation / quantité d\'eau apportée',
                            //     numeric: true,
                            // },
                            // {
                            //     field: 'rdt_avec_prochaine_irrig__div__qte_eau',
                            //     label: 'Rendement avec prochaine irrigation / quantité d\'eau apportée',
                            //     numeric: true,
                            // },
                            // {
                            //     field: 'gain_rdt__div__qte_eau_prochaine_irrig',
                            //     label: 'Gain de rendement / quantité d\'eau apportée prochaine irrigation',
                            //     numeric: true,
                            // },
                        ],           
                    },
                    resultatsTabSynthetique: 
                    {
                        data: 
                        [
                        ],
                        columns: [
                            {
                                field: 'label',
                                label: 'Variable d\'intérêt sélectionnée',
                            },
                            {
                                field: 'mediane',
                                label: 'Médiane',
                                numeric: true,
                            },
                            {
                                field: 'moy_et_moy_coeff',
                                label: 'Moyenne',
                                //width: '40',
                                numeric: true,
                            },
                            {
                                field: 'min_et_min_coeff',
                                label: 'Min',
                                numeric: true,
                            },
                            {
                                field: 'max_et_max_coeff',
                                label: 'Max',
                                numeric: true,
                            },
                            {
                                field: 'ecart_type',
                                label: 'Écart-type',
                                numeric: true,
                            },
                            {
                                field: 'decile_2',
                                label: '2nd décile',
                                numeric: true,
                            },
                            {
                                field: 'decile_8',
                                label: '8ème décile',
                                numeric: true,
                            },
                        ]    
                    },
                },
                resultat_evol_variables: {
                    chartsVarInteret: [],
                    chartsVarInteretCalc: [],
                    debutPeriodeZoomGraphique: null,
                    finPeriodeZoomGraphique: null,
                },
                texteChargementCalculs: "",
            },
            utilisateur: 
            {
                prochaineIrrigation: 
                {
                    date: null,
                    dose: null,
                }
            },
        },

        donneesEntreeTemplateVide: {
            meteo: [],
            itk: [],
            strategie_irrigation: null,
            caracterisation_sol: [],
            forcage: [],
            fichier_output: [],
        },

        tabOChart: [],
    };
    },
    computed: {
        URL_PASSERELLE: function () {
            return (process.env.VUE_APP_PASSERELLE_URL);
        },
    },
    watch: {
		"saisieUtilisateur.choixFct": {
			handler: function (newValue) {
				if(2 == newValue) {
					this.interFcts.utilisateur.optionIrrigation = 1;
				}
			}
		},
        "interFcts.utilisateur.variablesInteretCalculee": {
            deep: true,

            handler: function (newValue) {
                this.fct1.interne.resultat_evol_calibres.intervsCalibre = newValue?.map(classeCalibreI => [classeCalibreI.min, classeCalibreI.max]) ?? [];
			}
        }
    },
    created() {
        
    },
    beforeMount() {
        axios
            .get(
				`${this.URL_PASSERELLE}/api/utilisateurs/parcelles`,
			)
            .then((response) => {
                if (200 == response.status && !response.data.message) {
					this.donneesUtilisateur.parcelles = response.data;
				} 
				else if (response.data.message) {
					this.interne.textePopup = `Traitement terminé avec des erreurs (1) (${response.data.message})`;
				} 
				else {
					this.interne.textePopup = "Traitement terminé avec des erreurs (1)";
				}
            })
            .catch((error) => {
                console.error(error);
            });
    },
    mounted() {
        
    },
    methods: {
        fct1GenererResultats2MAPP() {
            //#region initialisation des variables
            let varInteretI = 'fct1resultat2MAPP';
            const X_LEGEND = this.fct1.interne.resultats.resultat_evol_variables.x;
            let sortie = this.fct1.interne.resultats.resultat_evol_variables;
            const NB_SCENARIOS_METEO = this.fct1.interne.resultats.config.inds_sims_scenarios_meteo.length;
            const IS_MODE_RESULTATS_AGREGES = this.isModeResultatsAgreges(NB_SCENARIOS_METEO);
            const ANNEE_METEO_UTILISATEUR = this.fct1.interne.resultats.config.derniere_date_meteo_utilisateur?.split('-')?.[0];
            const NB_DATES = sortie.mapp?.sortie?.[0]?.data?.[0]?.slice(2)?.length ?? 0;
            this.fct1.interne.resultat_evol_variables.chartsVarInteretMapp = [];

            let optionsDataType = {};
            let optionsDataGroups = [[]];
            
            let valuesPerGraph = valuesPerGraph = Array.from({length: sortie.mapp?.sortie?.length ?? 0}, () => Array.from({length: NB_DATES}, () => []) );
            let maxValuePerGraph = [];
            let maxValueOfAllGraph;
            //#endregion initialisation des variables

            sortie.mapp.intitules.forEach(intituleI => optionsDataType[intituleI] = 'area-spline');

            optionsDataGroups[0].push(...sortie.mapp.intitules);

            //#region Valeur max des valeurs (axe des ordonnées)
            sortie.mapp?.sortie?.forEach((sortieGraphiqueI, indSortieGraphiqueI) => {
                sortieGraphiqueI.data.forEach(tabGraphiqueI => {
                    tabGraphiqueI.slice(2).forEach((tabGraphiqueIEltI, indI) => {
                        valuesPerGraph[indSortieGraphiqueI][indI].push(tabGraphiqueIEltI);                        
                    });
                });
            });

            // POUR CHAQUE date de chaque graphique, somme des valeurs (de chaque courbe)
            // PUIS les valeurs chaque tableau mis dans un même tableau (concaténation)
            maxValuePerGraph = valuesPerGraph.flatMap(tabI => tabI).map(tabI => tabI.reduce((x, y) => Number(x) + Number(y), 0));

            // A SAVOIR : Math.max sur tableau vide = -Infinity. Pour l'opérateur de coalescence des nuls (??), et -Infinitiy 
            maxValueOfAllGraph = 0 == valuesPerGraph.length ? 0 : Math.max(...maxValuePerGraph) ?? 0;
            //#endregion Valeur max des valeurs (axe des ordonnées)
            
            sortie.mapp.sortie.forEach(sortieGraphiqueI => {
                let titleI;
                let jMoisDefanage = sortieGraphiqueI.date_defanage.split('-').slice(1).reverse().join('/');

                if (IS_MODE_RESULTATS_AGREGES) {
                    titleI = `${sortieGraphiqueI.nom_champ_agrege} (défanage : ${jMoisDefanage})`;
                } 
                else {
                    titleI = `météo ${ANNEE_METEO_UTILISATEUR}-${sortieGraphiqueI.scenario_meteo.annee} (défanage : ${jMoisDefanage})`;
                }                

                this.fct1.interne.resultat_evol_variables.chartsVarInteretMapp.push({                    
                    iduniquepourvuejs: varInteretI + '_' + titleI + Date.now(),
                    title: titleI,
                    X_LEGEND: X_LEGEND,
                    data: sortieGraphiqueI.data, //data: dataVarInteretI,
                    optionsData: {
                        types: optionsDataType,
                        groups: optionsDataGroups,
                        order: null, // Affichage dans l'ordre d'apparition des tableaux du tableau "data"
                    },
                    optionsAxisY: {
                        max: maxValueOfAllGraph,
                    }
                });
            });
        },
        /**
         * @param {number} nbScenariosClim Nb de scénarios
         * @returns {Boolean} si oui/non, on est en mode "résultats agrégés"
         */
        isModeResultatsAgreges(nbScenariosClim) {
            return (5 < nbScenariosClim);
        },
        changeParcelle() {
            let parcelle = this.donneesUtilisateur.parcelles.filter(parcelleI => parcelleI.id == this.saisieUtilisateur.idParcelle)[0]?.parcelle;
            let parcelleItk = parcelle?.itk;
            

            if(undefined === parcelleItk) {
                this.interne.parcelleSelectionne.data = [];
                this.fct1.configUtilisateur.dateDefanageItkParcelle = null;
            }
            else {
                this.interne.parcelleSelectionne.data = [
                    {'label': "Date de plantation", 'value': parcelleItk?.date_plantation.split('-').reverse().join('/')},
                    {'label': "Date de buttage", 'value': parcelleItk?.date_buttage.split('-').reverse().join('/')},                    
                    {'label': "Date de défanage prévisionnelle", 'value': parcelleItk?.date_defanage.split('-').reverse().join('/')},
                    {'label': "Date de récolte prévisionnelle", 'value': parcelleItk?.date_recolte.split('-').reverse().join('/')},
                    {'label': "Densité de plantation", 'value': parcelleItk?.densite_plantation + ' tubercules/ha'},
                    {'label': "Calibre du plant", 'value': parcelleItk?.plant_calibre + ' mm'},
                    {'label': "Nom de la variété", 'value': parcelleItk?.variete_nom},
                    {'label': "Commune et département", 'value': (parcelle?.parcelle?.nom_commune ?? '-') + ' (' + (parcelle?.parcelle?.code_dept ?? '-') + ')'},
                ];

                this.fct1.configUtilisateur.dateDefanageItkParcelle = parcelleItk?.date_defanage;
                this.saisieUtilisateur.meteo_temps_reel.code_dept = parcelle?.parcelle?.code_dept;
                this.saisieUtilisateur.meteo_temps_reel.ville = parcelle?.parcelle?.nom_commune;
            }
        },
        addVarInteretCalculee() {
            this.interFcts.utilisateur.variablesInteretCalculee.push({
                id: "VarInteretCalculee" + Date.now(),
                min: 15,
                max: 20,
            });
        },
        removeVarInteretCalculee(id) {
            const indItemToRemove =
                this.interFcts.utilisateur.variablesInteretCalculee.findIndex(
                    (eltI) => id == eltI.id
                );
            this.interFcts.utilisateur.variablesInteretCalculee.splice(
                indItemToRemove,
                1
            );
        },
        /**
         * conversion des données venant du composant irrigation dans les unités attendues pour le moteur de calcul.
         * @param {Object} localIrrigation
         */
        convertUniteDataIrrigSaisieToCalcul(localIrrigation) {
            let o1 = JSON.parse(JSON.stringify(localIrrigation));
            const configIrrig = this.interne.convertUniteDataSaisieToCalcul.config.irrigation;

            Object.keys(configIrrig.irrigation)
                .forEach(eltConfigI => o1[eltConfigI] = configIrrig.irrigation[eltConfigI](o1[eltConfigI]) );

            return o1;
        },        
        transformDataIrrigationToJson: function (localIrrigation) {
            let jsonIrrigation = {
                strategie: [],
                volume_irrigation_max: [],
                efficience_irrigation: [],
                stades_culture: [],
            };

            localIrrigation.phase.forEach((elt) => {
                jsonIrrigation.strategie.push(JSON.parse(JSON.stringify(elt)));
            });

            jsonIrrigation.volume_irrigation_max.push(
                localIrrigation.volume_irrigation_max
            );
            jsonIrrigation.efficience_irrigation.push(
                localIrrigation.efficience_irrigation
            );
            jsonIrrigation.stades_culture.push(localIrrigation.stade_culture);

            return jsonIrrigation;
        },        
        /**
         * @param templateDataJsonItk Template de la forme de données JSON attendu
         * @param localItk Données ITK saisies
         */
        transformDataItkToJson: function (templateDataJsonItk, localItk) {
            let jsonItk = JSON.parse(JSON.stringify(templateDataJsonItk));

            jsonItk.forEach((elementItk) => {
                if (
                    -1 != Object.keys(localItk).indexOf(elementItk.variable_id)
                ) {
                    elementItk.valeur = localItk[elementItk.variable_id];
                }
            });

            return JSON.parse(JSON.stringify(jsonItk));
        },
        addScenarioClimRef() {
            const annee = this.saisieUtilisateur.meteo_scenario.annee;

            const indItemToAdd =
                this.saisieUtilisateur.meteo_scenario.ref.findIndex(
                    (eltI) =>
                        annee == eltI.annee
                );

            if (-1 !== indItemToAdd) {
                // TODO A remplacer par quelque chose de visuel pour l'utilisateur (un popup, ...)
                console.log("Référence déjà ajoutée");

                return;
            }

            this.saisieUtilisateur.meteo_scenario.ref.push({
                annee: annee,
            });
        },
        removeScenarioClimRef(annee) {
            const indItemToRemove =
                this.saisieUtilisateur.meteo_scenario.ref.findIndex(
                    (eltI) => annee == eltI.annee
                );
            this.saisieUtilisateur.meteo_scenario.ref.splice(
                indItemToRemove,
                1
            );
        },        
        fct1RemplissageDonneesUtilisateur() {
            // //#region injection de données dans des champs de saisie
            this.interFcts.utilisateur.optionIrrigation = 2;
            
            this.interFcts.utilisateur.variablesInteretChecked = [
                "tubercules_matiere_fraiche",
                "tubercules_matiere_seche",
            ];

            //#endregion injection de données dans des champs de saisie
        },
        fct2RemplissageDonneesUtilisateur() {
            this.interFcts.utilisateur.optionIrrigation = 1;
            
            this.fct1.configUtilisateur.plageDefanage.dateDebut = "2019-08-31";
            this.fct1.configUtilisateur.plageDefanage.dateFin = "2019-09-10";
            this.fct1.configUtilisateur.plageDefanage.pas = 80;
            this.interFcts.utilisateur.variablesInteretChecked = [
                "tubercules_matiere_fraiche",
                "tubercules_matiere_seche",
            ];
        },
        async convertMeteoAnneeEnCoursCsvToJson() {
            if([undefined, null, ""].includes(this.saisieUtilisateur.meteo_temps_reel.fichier)) {
                return false;
            }

            const fd = new FormData();
            let fichier_meteo_temps_reel = this.saisieUtilisateur.meteo_temps_reel.fichier;

            fd.append(
                "fichier_meteo_temps_reel",
                fichier_meteo_temps_reel,
                fichier_meteo_temps_reel.filename
            );

            await axios
                .post(
                    `${this.URL_PASSERELLE}/api/interfcts/convert_meteo_csv_to_json`,
                    fd
                )
                .then((response) => {
                    if (200 == response.status && !response.data.message) {
                        this.fct1.interne.texteChargementCalculs = "Traitement terminé";
                 
                        this.saisieUtilisateur.meteo_temps_reel.jsonFromFichier = response.data;
                        return true;
                    } else if (response.data.message) {
                        this.fct1.interne.texteChargementCalculs = `Traitement terminé avec des erreurs (1) (${response.data.message})`;
                    } else {
                        this.fct1.interne.texteChargementCalculs =
                            "Traitement terminé avec des erreurs (1)";
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        this.fct1.interne.texteChargementCalculs = `Traitement terminé avec des erreurs (2) (${error.response.data?.error})`;
                    } else {
                        this.fct1.interne.texteChargementCalculs = `Traitement terminé avec des erreurs (2) (${error.message})`;
                    }
                });

                return false;
        },
        async fct1DonneesUtilisateurToResultatsSims() {
            await this.convertMeteoAnneeEnCoursCsvToJson();
            let idParcelle = this.saisieUtilisateur.idParcelle;

            this.fct1NettoyageResultats();

            let fct1VariablesInteretCalculee = [];
            const patternVariableInteretCalculee = {
                label: "calibre_-9999_a_9999",
                log: "log_calibres",
                filtre: {
                    taille_min: -9999,
                    taille_max: 9999,
                },
            };

            this.interFcts.utilisateur.variablesInteretCalculee.forEach(
                (un) => {
                    fct1VariablesInteretCalculee.push(
                        JSON.parse(
                            JSON.stringify(patternVariableInteretCalculee)
                                .replace(/-9999/g, un.min)
                                .replace(/9999/g, un.max)
                        )
                    );
                }
            );


            let dataIrrigation = this.transformDataIrrigationToJson(
                this.convertUniteDataIrrigSaisieToCalcul(
                    this.$refs.refIrrigation.localIrrigation
                )
            );
            let dataFichierOutput = JSON.parse(
                JSON.stringify(this.fichieroutput)
            );

            let JSONdata = JSON.parse(
                JSON.stringify(this.donneesEntreeTemplateVide)
            );

            JSONdata.meteo = this.saisieUtilisateur.meteo_temps_reel.jsonFromFichier;
            JSONdata.strategie_irrigation = dataIrrigation;
            JSONdata.forcage = null;
            JSONdata.fichier_output = dataFichierOutput;

            let dateDefanageSimDebut;
            let dateDefanageSimFin;
            let pasDateDefanageSim;

            if('date_defanage_parcelle' == this.fct1.configUtilisateur.optionDefanage) {
                dateDefanageSimDebut = this.fct1.configUtilisateur.dateDefanageItkParcelle;
                dateDefanageSimFin = this.fct1.configUtilisateur.dateDefanageItkParcelle;
                pasDateDefanageSim = 999;
            }

            let optionIrrigation = this.interFcts.utilisateur.optionIrrigation;
            let donneesEntree = {
                config: {
                    variables_interet: [
                        ...this.interFcts.utilisateur.variablesInteretChecked,
                        ...this.interFcts.utilisateur.variablesInteretAutreChecked,
                    ],
                    variables_interet_calculees: fct1VariablesInteretCalculee,
                    defanage: {
                        date_debut: dateDefanageSimDebut,
                        date_fin: dateDefanageSimFin,
                        pas_date: pasDateDefanageSim,
                    },
                    irrigation: {
                        option: optionIrrigation,
                    },
                    scenario_meteo: null, // sera rempli via pre_entree > meteo_scenario_reference
                    fct1_resultat_evol_calibres: {
                        dates: this.fct1.interne.resultat_evol_calibres.dates
                                .map((dateI) => "1970-" + dateI.split("/").reverse().join("-")),
                        classes_calibre: this.fct1.interne.resultat_evol_calibres.intervsCalibre,
                    },
                },
                pre_entree: {
                    parcelle: {id: idParcelle},
                    meteo_scenario_reference: this.saisieUtilisateur.meteo_scenario.ref,
                },
                entree: JSONdata,
            };

            this.fct1.interne.texteChargementCalculs = "Traitement en cours (patientez quelques instants)";

            axios
                .post(
                    `${this.URL_PASSERELLE}/api/fct1/pre_fct1_v3_to_resultats`,
                    donneesEntree
                )
                .then((response) => {
                    if (200 == response.status && !response.data.message) {
                        this.fct1.interne.texteChargementCalculs = "Traitement terminé";
                        this.fct1.interne.resultats = response.data;
                        this.fct1GenererResultats();
                    } else if (response.data.message) {
                        this.fct1.interne.texteChargementCalculs = `Traitement terminé avec des erreurs (1) (${response.data.message})`;
                    } else {
                        this.fct1.interne.texteChargementCalculs =
                            "Traitement terminé avec des erreurs (1)";
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        this.fct1.interne.texteChargementCalculs = `Traitement terminé avec des erreurs (2) (${error.response.data?.error})`;
                    } else {
                        this.fct1.interne.texteChargementCalculs = `Traitement terminé avec des erreurs (2) (${error.message})`;
                    }
                });
        },
        async fct2DonneesUtilisateurToResultatsSims() {
            await this.convertMeteoAnneeEnCoursCsvToJson();
            //const RET_CONVERT_METEO = await this.convertMeteoAnneeEnCoursCsvToJson();
            //console.log('convert meteo json : ', RET_CONVERT_METEO);
            let idParcelle = this.saisieUtilisateur.idParcelle;

            this.fct2NettoyageResultats();

            let fct2VariablesInteretCalculee = [];
            const patternVariableInteretCalculee = {
                label: "calibre_-9999_a_9999",
                log: "log_calibres",
                filtre: {
                    taille_min: -9999,
                    taille_max: 9999,
                },
            };

            this.interFcts.utilisateur.variablesInteretCalculee.forEach(
                (un) => {
                    fct2VariablesInteretCalculee.push(
                        JSON.parse(
                            JSON.stringify(patternVariableInteretCalculee)
                                .replace(/-9999/g, un.min)
                                .replace(/9999/g, un.max)
                        )
                    );
                }
            );


            let dataIrrigation = this.transformDataIrrigationToJson(
                this.convertUniteDataIrrigSaisieToCalcul(
                    this.$refs.refIrrigation.localIrrigation
                )
            );
            let dataFichierOutput = JSON.parse(
                JSON.stringify(this.fichieroutput)
            );

            let JSONdata = JSON.parse(
                JSON.stringify(this.donneesEntreeTemplateVide)
            );

            JSONdata.meteo = this.saisieUtilisateur.meteo_temps_reel.jsonFromFichier;
            JSONdata.strategie_irrigation = dataIrrigation;
            JSONdata.forcage = null;
            JSONdata.fichier_output = dataFichierOutput;

            let optionIrrigation = this.interFcts.utilisateur.optionIrrigation;

            let donneesEntree = {
                config: {
                    variables_interet: [
                        ...this.interFcts.utilisateur.variablesInteretChecked,
                        ...this.interFcts.utilisateur.variablesInteretAutreChecked,
                    ],
                    variables_interet_calculees: fct2VariablesInteretCalculee,
                    irrigation: {
                        option: optionIrrigation,
                    },
					prochaine_irrigation: {
						date: this.fct2.utilisateur.prochaineIrrigation.date,
						dose: this.fct2.utilisateur.prochaineIrrigation.dose,
					},
                    scenario_meteo: null, // sera rempli via pre_entree > meteo_scenario_reference
                },
                pre_entree: {
                    parcelle: {id: idParcelle},
                    meteo_scenario_reference: this.saisieUtilisateur.meteo_scenario.ref,
                },
                entree: JSONdata,
            };

            this.fct2.interne.texteChargementCalculs = "Traitement en cours (patientez quelques instants)";

            axios
                .post(
                    `${this.URL_PASSERELLE}/api/fct2/pre_fct2_v3_to_resultats`,
					donneesEntree,
                )
                .then((response) => {
                    if (200 == response.status && !response.data.message) {
                        this.fct2.interne.texteChargementCalculs = "Traitement terminé";
                        this.fct2.interne.resultats = response.data;
                        this.fct2GenererResultats();
                    } else if (response.data.message) {
                        this.fct2.interne.texteChargementCalculs = `Traitement terminé avec des erreurs (1) (${response.data.message})`;
                    } else {
                        this.fct2.interne.texteChargementCalculs = "Traitement terminé avec des erreurs (1)";
                    }
                })
                .catch((error) => {
                    if (error.response) {console.log(error.response);
                        this.fct2.interne.texteChargementCalculs = `Traitement terminé avec des erreurs (2) (${error.response.data?.error})`;
                    } else {
                        this.fct2.interne.texteChargementCalculs = `Traitement terminé avec des erreurs (2.) (${error.message})`;
                    }
                });
        },
        fct1GenererResultats() {
            this.fct1GenererResultats1();
            this.fct1GenererResultats2();
            this.fct1GenererResultats2MAPP();
            this.fct1GenererResultatsEtatDesLieux();
            this.fct1GenererResultatsEvolCalibres();
        },
        fct1GenererResultats1() {
            //#region initialisation des variables
            const fctFormatNum = new Intl.NumberFormat("en-EN", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });

            let varsInteretCalc = [];

            this.interFcts.utilisateur.variablesInteretCalculee.forEach(
                (un) => {
                    varsInteretCalc.push(
                        "calibre_-9999_a_9999_poids"
                            .replace(/-9999/g, un.min)
                            .replace(/9999/g, un.max)
                    );
                }
            );

            const RESULTATS = this.fct1.interne.resultats.resultat1.sortie;
            const NB_SCENARIOS_METEO =
                this.fct1.interne.resultats.config.inds_sims_scenarios_meteo
                    .length;
            const IS_MODE_RESULTATS_AGREGES = this.isModeResultatsAgreges(NB_SCENARIOS_METEO);
            const VARS_INTERET = [
                ...this.interFcts.utilisateur.variablesInteretChecked,
                ...this.interFcts.utilisateur.variablesInteretAutreChecked,
            ].filter(
                (eltI) =>
                    !this.fct1.interne.resultat1.VARS_INTERET_EXCLUDED.includes(
                        eltI
                    )
            );
            const VARS_INTERET_CALC = varsInteretCalc;

            const INTITULES_VARS_INTERET_ALL = [
                ...VARS_INTERET,
                ...VARS_INTERET_CALC,
            ].reduce((acc, curr) => {
                if (VARS_INTERET.includes(curr)) {
                    acc[curr] = [
                        ...this.constant.variables_interet_principale.values,
                        ...this.constant.variables_interet_supplementaire.values,
                    ]
                        .filter((elt) => curr == elt.value)
                        .map((elt) => elt.text)[0];
                } else {
                    acc[curr] =
                        helperCalibre.fctCalibreTransfoNomVariableEnLabel(curr);
                }

                return acc;
            }, {});

            const ANNEES_SIMS = this.fct1.interne.resultats.config.annees_scenarios_meteo_sims;
            const ANNEES_UNIQUE_SIMS = ANNEES_SIMS.filter(
                (dateI, indDateI) => ANNEES_SIMS.indexOf(dateI) == indDateI
            );

            if (IS_MODE_RESULTATS_AGREGES) {
                this.fct1.interne.resultat1.resultatsTabImpactsDateDefanage.columns.forEach(elemI => {
                    if(['decile_2', 'decile_8', 'mediane'].includes(elemI.field)) {
                        elemI.visible = true;                        
                    }
                });               
            } else {
                this.fct1.interne.resultat1.resultatsTabImpactsDateDefanage.columns.forEach(elemI => {
                    if(['decile_2', 'decile_8', 'mediane'].includes(elemI.field)) {
                        elemI.visible = false;                        
                    }
                });              
            }

            //#endregion initialisation des variables

            //#region formatage données
            // tableau par variable d'intérêt
            // remplissage de bodyTabResultatByVarInteret

            let tmp = {};


            //#region remplissage tableau résultat buefy

            [...VARS_INTERET, ...VARS_INTERET_CALC].forEach((var_interet) => {
                Object.keys(RESULTATS).forEach((uneDate) => {
                    tmp = RESULTATS[uneDate][var_interet];
                    tmp['label'] = INTITULES_VARS_INTERET_ALL[var_interet];
                    ['min', 'max', 'moyenne', 'mediane', 'decile_2', 'decile_8', 'ecart_type'].forEach(champI => {
                        //if(tmp.hasOwnProperty(champI)) {
                        if(champI in tmp) {
                            tmp[champI] = fctFormatNum.format(tmp[champI]);
                        }
                    });

                    this.fct1.interne.resultat1.resultatsTabImpactsDateDefanage.data.push(JSON.parse(JSON.stringify(tmp)));         
                });
            });

            //#endregion remplissage tableau résultat buefy


            this.fct1.interne.resultat1.resultatsTabSynthetique.columns = [
                {
                    field: 'date_defanage',
                    label: 'Date de défanage',
                },
                {
                    field: 'annee',
                    label: 'Année',
                },
            ];

            [...VARS_INTERET, ...VARS_INTERET_CALC].forEach((var_interet) => {
                this.fct1.interne.resultat1.resultatsTabSynthetique.columns.push({
                    field: var_interet,
                    label: INTITULES_VARS_INTERET_ALL[var_interet],
                    numeric: true,
                });
            });

            Object.keys(RESULTATS).forEach((dateResI) => {
                const J_MOIS_RES_I = dateResI
                    .substring(5)
                    .split("-")
                    .reverse()
                    .join("/");

                ANNEES_UNIQUE_SIMS.forEach((anneeI, indAnneeI) => {        
                    let tmpValeursVarInteretI = {};        

                    [...VARS_INTERET, ...VARS_INTERET_CALC].forEach(
                        (varInteretI) => {
                            tmpValeursVarInteretI[varInteretI] = fctFormatNum.format(
                                RESULTATS[dateResI][varInteretI]["liste"][
                                    indAnneeI
                                ]
                            );
                        }
                    );

                    this.fct1.interne.resultat1.resultatsTabSynthetique.data.push({
                        'date_defanage': J_MOIS_RES_I,
                        'annee': anneeI,
                        ...tmpValeursVarInteretI
                    });
                });
            });
        },
        fct1GenererResultats2() {
            let varsInteretCalc = [];

            this.fct1.interne.resultat_evol_variables.chartsVarInteret = [];
            this.fct1.interne.resultat_evol_variables.chartsVarInteretCalc = [];

            this.interFcts.utilisateur.variablesInteretCalculee.forEach(
                (un) => {
                    varsInteretCalc.push(
                        "calibre_-9999_a_9999_poids"
                            .replace(/-9999/g, un.min)
                            .replace(/9999/g, un.max)
                    );
                }
            );

            const VARS_INTERET = [
                ...this.interFcts.utilisateur.variablesInteretChecked,
                ...this.interFcts.utilisateur.variablesInteretAutreChecked,
            ];
            const VARS_INTERET_CALC = varsInteretCalc;
            const NB_SCENARIOS_METEO =
                this.fct1.interne.resultats.config.inds_sims_scenarios_meteo
                    .length;
            const IS_MODE_RESULTATS_AGREGES = this.isModeResultatsAgreges(NB_SCENARIOS_METEO);

            const INDS_SIM_SELECTION =
                this.fct1.interne.resultats.config.inds_sims_mix_meteo;
            const ANNEES_SCENARIO_METEO_SIMS =
                this.fct1.interne.resultats.config.annees_scenarios_meteo_sims;
            const ANNEES_SCENARIO_METEO_UNIQUE =
                ANNEES_SCENARIO_METEO_SIMS.filter(
                    (uneDate, indUneDate) =>
                        -1 != INDS_SIM_SELECTION.indexOf(indUneDate) &&
                        ANNEES_SCENARIO_METEO_SIMS.indexOf(uneDate) == indUneDate
                );
            let optionsData = {
                colors: {},
                classes: {},
            };

            
            if (IS_MODE_RESULTATS_AGREGES) {
                const X_LEGEND = this.fct1.interne.resultats.resultat_evol_variables.x;
//#region config courbe (apparence)
                this.fct1.interne.resultats.resultat_evol_variables.intitules.forEach((intituleI, indIntituleI) => {
                    optionsData.colors[intituleI] =
                        this.interFcts.interne.DEFAULT_COLORS_LINE_CHART[
                            Math.floor(indIntituleI / 3)
                        ];

                    optionsData.classes[intituleI] =
                    this.interFcts.interne.DEFAULT_STYLES_LINE_CHART[
                        (indIntituleI % 3) + 1
                    ];
                });

            //#endregion config courbe (apparence)
                //optionsData.classes["2019-08-31|||mediane"] = "medium_dashed_line";

                //#region variables d'intérêt
                VARS_INTERET.forEach((varInteretI) => {
                    let dataVarInteretI = [];
                    Object.keys(this.fct1.interne.resultats.resultat_evol_variables.sortie).forEach(dateDefanageI => 
                            dataVarInteretI.push(...this.fct1.interne.resultats.resultat_evol_variables.sortie[dateDefanageI][varInteretI]));
                    
                    const title = [
                        ...this.constant.variables_interet_principale.values,
                        ...this.constant.variables_interet_supplementaire.values,
                    ]
                        .filter((elt) => varInteretI == elt.value)
                        .map((elt) => elt.text)[0];

                    this.fct1.interne.resultat_evol_variables.chartsVarInteret.push({
                        iduniquepourvuejs: varInteretI + Date.now(),
                        title: title,
                        X_LEGEND: X_LEGEND,
                        data: dataVarInteretI,
                        optionsData: optionsData,
                    });
                });

                //#endregion variables d'intérêt

                //#region variables d'intérêt calculées

                VARS_INTERET_CALC.forEach((varInteretI) => {
                    let dataVarInteretI = [];
                    Object.keys(this.fct1.interne.resultats.resultat_evol_variables.sortie).forEach(dateDefanageI => 
                        dataVarInteretI.push(...this.fct1.interne.resultats.resultat_evol_variables.sortie[dateDefanageI][varInteretI]));

                    const title =
                        helperCalibre.fctCalibreTransfoNomVariableEnLabel(
                            varInteretI
                        ); //this.constant.variables_interet_principale.values.filter(elt => varInteretI == elt.value).map(elt => elt.text)[0];

                    this.fct1.interne.resultat_evol_variables.chartsVarInteretCalc.push({
                        iduniquepourvuejs: varInteretI + Date.now(),
                        title: title,
                        X_LEGEND: X_LEGEND,
                        data: dataVarInteretI,
                        optionsData: optionsData,
                    });
                });

                //#endregion variables d'intérêt calculées
            } else {
                // mode résultats NON AGRÉGÉS
                const X_LEGEND = this.fct1.interne.resultats.resultat_evol_variables.x;

//#region config courbe (apparence)
                this.fct1.interne.resultats.config.intitules.forEach(
                    (intituleI, indIntituleI) => {
                        optionsData.colors[intituleI] =
                            this.interFcts.interne.DEFAULT_COLORS_LINE_CHART[
                                indIntituleI
                            ];
                    }
                );

                INDS_SIM_SELECTION.forEach((indSimMixMeteoI, indSimSelectionI) => {
                    let indStyleLineChart = ANNEES_SCENARIO_METEO_UNIQUE.indexOf(
                        ANNEES_SCENARIO_METEO_SIMS[indSimSelectionI]
                    );
                    let intituleI =
                        this.fct1.interne.resultats.config.intitules[
                            indSimSelectionI
                        ];
                    optionsData.classes[intituleI] =
                        this.interFcts.interne.DEFAULT_STYLES_LINE_CHART[
                            indStyleLineChart
                        ];
                });
                //#endregion config courbe (apparence)

                //#region variables d'intérêt
                VARS_INTERET.forEach((varInteretI) => {
                    const dataVarInteretI =
                        this.fct1.interne.resultats.resultat_evol_variables.sortie.map(
                            (elt) => elt[varInteretI]
                        );
                    const title = [
                        ...this.constant.variables_interet_principale.values,
                        ...this.constant.variables_interet_supplementaire.values,
                    ]
                        .filter((elt) => varInteretI == elt.value)
                        .map((elt) => elt.text)[0];

                    this.fct1.interne.resultat_evol_variables.chartsVarInteret.push({
                        iduniquepourvuejs: varInteretI + Date.now(),
                        title: title,
                        X_LEGEND: X_LEGEND,
                        data: dataVarInteretI,
                        optionsData: optionsData,
                    });
                });

                //#endregion variables d'intérêt

                //#region variables d'intérêt calculées

                VARS_INTERET_CALC.forEach((varInteretI) => {
                    const dataVarInteretI =
                        this.fct1.interne.resultats.resultat_evol_variables.sortie.map(
                            (elt) => elt[varInteretI]
                        );
                    const title =
                        helperCalibre.fctCalibreTransfoNomVariableEnLabel(
                            varInteretI
                        ); //this.constant.variables_interet_principale.values.filter(elt => varInteretI == elt.value).map(elt => elt.text)[0];

                    this.fct1.interne.resultat_evol_variables.chartsVarInteretCalc.push({
                        iduniquepourvuejs: varInteretI + Date.now(),
                        title: title,
                        X_LEGEND: X_LEGEND,
                        data: dataVarInteretI,
                        optionsData: optionsData,
                    });
                });
                //#endregion variables d'intérêt calculées
            }
        },
        fct1GenererResultatsEtatDesLieux() {
            //#region initialisation des variables
            let varsInteretCalc = [];

            this.fct1.interne.resultat_etat_des_lieux.chartsVarInteret = [];
            this.fct1.interne.resultat_etat_des_lieux.chartsVarInteretCalc = [];

            this.interFcts.utilisateur.variablesInteretCalculee.forEach(
                (un) => {
                    varsInteretCalc.push(
                        "calibre_-9999_a_9999_poids"
                            .replace(/-9999/g, un.min)
                            .replace(/9999/g, un.max)
                    );
                }
            );

            const RESULTATS = this.fct1.interne.resultats.resultat_etat_des_lieux;
            const X_LEGEND = RESULTATS.x;
            const VARS_INTERET = [
                ...this.interFcts.utilisateur.variablesInteretChecked,
                ...this.interFcts.utilisateur.variablesInteretAutreChecked,
            ];
            const VARS_INTERET_CALC = varsInteretCalc;

            const NB_SCENARIOS_METEO = this.fct1.interne.resultats.config.inds_sims_scenarios_meteo.length;
            const IS_MODE_RESULTATS_AGREGES = this.isModeResultatsAgreges(NB_SCENARIOS_METEO);

            this.fct1.interne.resultat_etat_des_lieux.chartsVarInteret = [];

            
            let optionsData = {
                colors: {},
                classes: {},
            };
            //#endregion initialisation des variables

            
            if (IS_MODE_RESULTATS_AGREGES) {
                //#region config courbe (apparence)
                this.fct1.interne.resultats.resultat_etat_des_lieux.intitules.forEach((intituleI, indIntituleI) => {
                    optionsData.colors[intituleI] =
                        this.interFcts.interne.DEFAULT_COLORS_LINE_CHART[
                            (!indIntituleI) ? 1 : 0    // indIntituleI = 0 = situation réelle
                        ];

                    optionsData.classes[intituleI] =
                    this.interFcts.interne.DEFAULT_STYLES_LINE_CHART[
                        (!indIntituleI) ? 3 : (indIntituleI % 3)
                    ];
                });
                //#endregion config courbe (apparence)
            } 
            else {
                //#region config courbe (apparence)
                this.fct1.interne.resultats.config.intitules.forEach(
                    (intituleI, indIntituleI) => {
                        optionsData.colors[intituleI] =
                            this.interFcts.interne.DEFAULT_COLORS_LINE_CHART[
                                indIntituleI
                            ];
                    }
                );
                //#endregion config courbe (apparence) 
            }

            //#region variables d'intérêt
            VARS_INTERET.forEach((varInteretI) => {
                let DONNEES_COURBE_I;

                if (!IS_MODE_RESULTATS_AGREGES) {
                    DONNEES_COURBE_I =
                    this.fct1.interne.resultats.resultat_etat_des_lieux.sortie.map(
                        (elt) => elt[varInteretI]
                    );
                }
                else {
                    DONNEES_COURBE_I = this.fct1.interne.resultats.resultat_etat_des_lieux.sortie[varInteretI];
                }
                
                const title = [
                    ...this.constant.variables_interet_principale.values,
                    ...this.constant.variables_interet_supplementaire.values,
                ]
                    .filter((elt) => varInteretI == elt.value)
                    .map((elt) => elt.text)[0];

                this.fct1.interne.resultat_etat_des_lieux.chartsVarInteret.push({
                    iduniquepourvuejs: varInteretI + Date.now(),
                    title: title,
                    X_LEGEND: X_LEGEND,
                    data: DONNEES_COURBE_I,
                    optionsData: optionsData,
                });
            });
            //#endregion variables d'intérêt

            //#region variables d'intérêt calculées
            VARS_INTERET_CALC.forEach((varInteretI) => {
                let dataVarInteretI;
                
                if (!IS_MODE_RESULTATS_AGREGES) {
                    dataVarInteretI =
                        this.fct1.interne.resultats.resultat_etat_des_lieux.sortie.map(
                            (elt) => elt[varInteretI]
                        );
                }
                else {
                    dataVarInteretI = this.fct1.interne.resultats.resultat_etat_des_lieux.sortie[varInteretI];
                }

                const title =
                    helperCalibre.fctCalibreTransfoNomVariableEnLabel(
                        varInteretI
                    ); //this.constant.variables_interet_principale.values.filter(elt => varInteretI == elt.value).map(elt => elt.text)[0];

                this.fct1.interne.resultat_etat_des_lieux.chartsVarInteretCalc.push({
                    iduniquepourvuejs: varInteretI + Date.now(),
                    title: title,
                    X_LEGEND: X_LEGEND,
                    data: dataVarInteretI,
                    optionsData: optionsData,
                });
            });
            //#endregion variables d'intérêt calculées
        },
        fct1GenererResultatsEvolCalibres() {
            this.fct1.interne.resultat_evol_calibres.charts = [];
            //const UNITE_CALIBRE = "mm";
            let resultatEvolCalibres = this.fct1.interne.resultats.resultat_evol_calibres;
            const NB_BATONS_EMPIL = resultatEvolCalibres.intitules.length;
            let valuesPerGraph = Array.from({length: resultatEvolCalibres.sortie.length}, () => Array.from({length: NB_BATONS_EMPIL}, () => []) );
            
            //#region Définition de la valeur max d'un bâton empilé sur l'ensemble des bâtons
            // (en Y, soit la valeur max d'un bâton empilé) de tous les graphiques 'évolution de répartition des calibres'
            resultatEvolCalibres.sortie.map(eltI => eltI.poids).forEach((sortieGraphiqueI, indSortieGraphiqueI) => {
                sortieGraphiqueI.forEach(tabGraphiqueI => {
                    tabGraphiqueI.slice(1).forEach((tabGraphiqueIEltI, indI) => {
                        valuesPerGraph[indSortieGraphiqueI][indI].push(tabGraphiqueIEltI);
                    });
                });
            });
            let maxValuePerGraph = valuesPerGraph.flatMap(tabI => tabI).map(tabI => tabI.reduce((x, y) => Number(x) + Number(y), 0))
            const MAX_VALUE_OF_ALL_GRAPH = Math.max(...maxValuePerGraph) ?? 0;
            //#endregion Définition de la valeur max d'un bâton empilé sur l'ensemble des bâtons

            this.fct1.interne.resultats.resultat_evol_calibres.sortie.forEach((resultatI, indResultatI) => {
                let resultatIFinal = JSON.parse(
                    JSON.stringify(this.fct1.interne.resultats.resultat_evol_calibres)
                );
                resultatIFinal["data"] = this.fct1.interne.resultats.resultat_evol_calibres.sortie[indResultatI]["poids"]; //.reverse();
                resultatIFinal["X_LEGEND"] = this.fct1.interne.resultats.resultat_evol_calibres["intitules"];
                resultatIFinal["optionsData"] = {
                    order: null, // ordre de définition des courbes du tableau
                    groups: [
                        this.fct1.interne.resultats.resultat_evol_calibres.classes_calibre
                            .map((classeI) => classeI.join("-")),
                    ],
                    names: this.fct1.interne.resultats.resultat_evol_calibres.classes_calibre.reduce(
                        (acc, curr) => {
                            if (500 == curr[1]) {
                                acc[curr.join("-")] = [`>${curr[0]}`];// + UNITE_CALIBRE;
                            } else {
                                acc[curr.join("-")] = curr.join("-");// + UNITE_CALIBRE;
                            }

                            return acc;
                        },
                        {}
                    ),
                };
                resultatIFinal["optionsAxisY"] = {
                    min: 0, // borne min pour tous les graphiques (sinon on pourrait avoir des bornes min négatives selon les cas (valeur max : très faible))
                    max: MAX_VALUE_OF_ALL_GRAPH,
                    padding: {
                        bottom: 0, // l'axe Y commencera vraiment à la borne min défini (sans cela, il y a une padding)
                    }
                };

                this.fct1.interne.resultat_evol_calibres.charts.push(resultatIFinal);
            });
        },
        /**
         * Réactualisation des résultats 4 de la fonctionnalité 1
         */
        fct1ReactualiserResultatsEvolCalibres() {
            this.fct1.interne.resultat_evol_calibres.charts = [];

            let donneesEntree = {
                config: {
                    ...this.fct1.interne.resultats.config,
                    fct1_resultat_evol_calibres: {
                        dates: this.fct1.interne.resultat_evol_calibres.dates
                                .map((dateI) => "1970-" + dateI.split("/").reverse().join("-")),
                        classes_calibre: this.fct1.interne.resultat_evol_calibres.intervsCalibre,
                    },
                },
                entree: this.fct1.interne.resultats.resultat_evol_calibres.entree,
            };

            axios.post(
                `${this.URL_PASSERELLE}/api/resultats/fct1_resultat_evol_calibres`,
                donneesEntree
            )
            .then((response) => {
                if (200 == response.status && !response.data.message) {
                    //this.fct1.interne.texteChargementCalculs = "Traitement terminé";
                    this.fct1.interne.resultats.resultat_evol_calibres = response.data;
                    this.fct1GenererResultatsEvolCalibres();
                } 
                else if (response.data.message) {
                    //this.fct1.interne.texteChargementCalculs = `Traitement terminé avec des erreurs (1) (${response.data.message})`;
                } 
                else {
                    //this.fct1.interne.texteChargementCalculs = "Traitement terminé avec des erreurs (1)";
                }
            })
            .catch((error) => {
                if (error.response) {
                    //this.fct1.interne.texteChargementCalculs = `Traitement terminé avec des erreurs (2) (${error.response.data?.error})`;
                } 
                else {
                    //this.fct1.interne.texteChargementCalculs = `Traitement terminé avec des erreurs (2) (${error.message})`;
                }
            });
        },
        fct2GenererResultats() {
            this.fct2GenererResultats1();
            this.fct2GenererResultatsEvolVariables();
            //this.fct2GenererResultatsEvolVariablesMAPP();
            //this.fct2GenererResultatsEvolCalibres();
        },
        fct2GenererResultats1() {
            let resultatsTab = 
                Object.keys(this.fct2.interne.resultats.resultat1.sortie.resultats)
                    .filter((eltI) =>
                        !this.fct2.interne.resultat1.VARS_INTERET_EXCLUDED.includes(eltI)
                    )
                    .flatMap(varInteretI => {
                        return Object.values(this.fct2.interne.resultats.resultat1.sortie.resultats[varInteretI])
                    });            

            this.fct2.interne.resultat1.resultatsTab.data = resultatsTab;


            let resultatsTabSynthese = Object
                .keys(this.fct2.interne.resultats.resultat1.sortie.synthese)
                .filter((eltI) =>
                    !this.fct2.interne.resultat1.VARS_INTERET_EXCLUDED.includes(eltI)
                )
                .flatMap(varInteretI => {
                    //return Object.values(this.fct2.interne.resultats.resultat1.sortie.synthese[varInteretI])
                    return this.fct2.interne.resultats.resultat1.sortie.synthese[varInteretI]
                });            

            this.fct2.interne.resultat1.resultatsTabSynthetique.data = resultatsTabSynthese;
        },
        fct2GenererResultatsEvolVariables() {
            let varsInteretCalc = [];

            this.fct2.interne.resultat_evol_variables.chartsVarInteret = [];
            this.fct2.interne.resultat_evol_variables.chartsVarInteretCalc = [];

            this.interFcts.utilisateur.variablesInteretCalculee.forEach(
                (un) => {
                    varsInteretCalc.push(
                        "calibre_-9999_a_9999_poids"
                            .replace(/-9999/g, un.min)
                            .replace(/9999/g, un.max)
                    );
                }
            );

            const VARS_INTERET = [
                ...this.interFcts.utilisateur.variablesInteretChecked,
                ...this.interFcts.utilisateur.variablesInteretAutreChecked,
            ];
            const VARS_INTERET_CALC = varsInteretCalc;
            const NB_SIMS = this.fct2.interne.resultats.config.annees_sims.length;
            const NB_SCENARIOS_METEO = NB_SIMS / 2;
            const IS_MODE_RESULTATS_AGREGES = this.isModeResultatsAgreges(NB_SCENARIOS_METEO);
            const INDS_SIM_SELECTION =[...Array(NB_SIMS).keys()].map((i) => i);
            
            let optionsData = {
                colors: {},
                classes: {},
            };

            if (IS_MODE_RESULTATS_AGREGES) {                 
                const X_LEGEND = this.fct2.interne.resultats.resultat_evol_variables.x;

                //#region config courbe (apparence)
                this.fct2.interne.resultats.resultat_evol_variables.intitules.forEach((intituleI, indIntituleI) => {
                    optionsData.colors[intituleI] =
                        this.interFcts.interne.DEFAULT_COLORS_LINE_CHART[
                            Math.floor(indIntituleI / 3)
                        ];

                    optionsData.classes[intituleI] =
                    this.interFcts.interne.DEFAULT_STYLES_LINE_CHART[
                        (indIntituleI % 3) + 1
                    ];
                });
                //#endregion config courbe (apparence)

                //#region variables d'intérêt
                VARS_INTERET.forEach((varInteretI) => {
                    const dataVarInteretI = this.fct2.interne.resultats.resultat_evol_variables.sortie.flatMap(sortieI => 
                        sortieI[varInteretI]);
                    const title = [
                        ...this.constant.variables_interet_principale.values,
                        ...this.constant.variables_interet_supplementaire.values,
                    ]
                    .filter((elt) => varInteretI == elt.value)
                    .map((elt) => elt.text)[0];

                    this.fct2.interne.resultat_evol_variables.chartsVarInteret.push({
                        iduniquepourvuejs: varInteretI + Date.now(),
                        title: title,
                        X_LEGEND: X_LEGEND,
                        data: dataVarInteretI,
                        optionsData: optionsData,
                    });
                });
                //#endregion variables d'intérêt

                //#region variables d'intérêt calculées
                VARS_INTERET_CALC.forEach((varInteretI) => {
                    const dataVarInteretI = this.fct2.interne.resultats.resultat_evol_variables.sortie.flatMap(sortieI => sortieI[varInteretI]);
                    const title =
                        helperCalibre.fctCalibreTransfoNomVariableEnLabel(
                            varInteretI
                        );

                    this.fct2.interne.resultat_evol_variables.chartsVarInteretCalc.push({
                        iduniquepourvuejs: varInteretI + Date.now(),
                        title: title,
                        X_LEGEND: X_LEGEND,
                        data: dataVarInteretI,
                        optionsData: optionsData,
                    });
                });
                //#endregion variables d'intérêt calculées
            } 
            else 
            {
                // mode résultat NON AGRÉGÉS
                const X_LEGEND = this.fct2.interne.resultats.resultat_evol_variables.x;

                //#region config courbe (apparence)
                // définition de la même couleur pour chaque paire de courbes : Math.trunc(indIntituleI / 2)
                this.fct2.interne.resultats.config.intitules.forEach(
                    (intituleI, indIntituleI) => {
                        optionsData.colors[intituleI] =
                            this.interFcts.interne.DEFAULT_COLORS_LINE_CHART[
                                Math.trunc(indIntituleI / 2)
                            ];
                    }
                );

                INDS_SIM_SELECTION.forEach((indSimMixMeteoI, indSimSelectionI) => {
                    let indStyleLineChart = indSimSelectionI % 2;
                    let intituleI =
                        this.fct2.interne.resultats.config.intitules[
                            indSimSelectionI
                        ];
                    optionsData.classes[intituleI] =
                        this.interFcts.interne.DEFAULT_STYLES_LINE_CHART[
                            indStyleLineChart
                        ];
                });
                //#endregion config courbe (apparence)

                //#region variables d'intérêt
                VARS_INTERET.forEach((varInteretI) => {
                    const dataVarInteretI =
                        this.fct2.interne.resultats.resultat_evol_variables.sortie.map(
                            (elt) => elt[varInteretI]
                        );
                    const title = [
                        ...this.constant.variables_interet_principale.values,
                        ...this.constant.variables_interet_supplementaire.values,
                    ]
                    .filter((elt) => varInteretI == elt.value)
                    .map((elt) => elt.text)[0];

                    this.fct2.interne.resultat_evol_variables.chartsVarInteret.push({
                        iduniquepourvuejs: varInteretI + Date.now(),
                        title: title,
                        X_LEGEND: X_LEGEND,
                        data: dataVarInteretI,
                        optionsData: optionsData,
                    });
                });
                //#endregion variables d'intérêt

                //#region variables d'intérêt calculées

                VARS_INTERET_CALC.forEach((varInteretI) => {
                    const dataVarInteretI =
                        this.fct2.interne.resultats.resultat_evol_variables.sortie.map(
                            (elt) => elt[varInteretI]
                        );
                    const title =
                        helperCalibre.fctCalibreTransfoNomVariableEnLabel(
                            varInteretI
                        );

                    this.fct2.interne.resultat_evol_variables.chartsVarInteretCalc.push({
                        iduniquepourvuejs: varInteretI + Date.now(),
                        title: title,
                        X_LEGEND: X_LEGEND,
                        data: dataVarInteretI,
                        optionsData: optionsData,
                    });
                });
                //#endregion variables d'intérêt calculées
            }
        },
        
        /**
         * Redéfinition avec un intervalle de date des données constituant chaque composant de "chartc3jsline".
         * @param {ChartC3JsLine[]} chartsc3jsline
         * @param {String} debutPeriode format JJ/MM
         * @param {String} finPeriode format JJ/MM
         */
        zoomPeriode(chartsc3jsline, debutPeriode, finPeriode) {
            let dateDebutGraphique = debutPeriode
                .split("/")
                .reverse()
                .join("-");
            let dateFinGraphique = finPeriode.split("/").reverse().join("-");

            chartsc3jsline.forEach((chartc3jslineI) => {
                chartc3jslineI.zoomPeriode(
                    dateDebutGraphique,
                    dateFinGraphique
                );
            });
        },
        /**
         * Reinit du zoom de période de chaque composant de "chartc3jsline".
         * @param {ChartC3JsLine[]} chartsc3jsline
         * @param {String} debutPeriode format JJ/MM
         * @param {String} finPeriode format JJ/MM
         */
        reinitZoomPeriode(chartsc3jsline) {
            chartsc3jsline.forEach((chartc3jslineI) => {
                chartc3jslineI.reinitZoomPeriode();
            });
        },
        /*fct1ExportCsvResultatsLogCulture() {
            const dataArray =
                this.fct1.interne.resultats.sorties_sims.sortie_culture.map(
                    (eltI) => eltI.join(";")
                );
            exportCsv(dataArray, { filename: "fct1_log_culture.csv" });
        },
        fct1ExportCsvResultatsLogCalibres() {
            const dataArray =
                this.fct1.interne.resultats.sorties_sims.sortie_calibres.map(
                    (eltI) => eltI.join(";")
                );
            exportCsv(dataArray, { filename: "fct1_log_calibres.csv" });
        },
        fct1ExportCsvResultatsLogPhases() {
            const dataArray =
                this.fct1.interne.resultats.sorties_sims.sortie_phases.map(
                    (eltI) => eltI.join(";")
                );
            exportCsv(dataArray, { filename: "fct1_log_phases.csv" });
        },
        fct2ExportCsvResultatsLogCulture() {
            const dataArray =
                this.fct2.interne.resultats.sorties_sims.sortie_culture.map(
                    (eltI) => eltI.join(";")
                );
            exportCsv(dataArray, { filename: "fct2_log_culture.csv" });
        },
        fct2ExportCsvResultatsLogCalibres() {
            const dataArray =
                this.fct2.interne.resultats.sorties_sims.sortie_calibres.map(
                    (eltI) => eltI.join(";")
                );
            exportCsv(dataArray, { filename: "fct2_log_calibres.csv" });
        },
        fct2ExportCsvResultatsLogPhases() {
            const dataArray =
                this.fct2.interne.resultats.sorties_sims.sortie_phases.map(
                    (eltI) => eltI.join(";")
                );
            exportCsv(dataArray, { filename: "fct2_log_phases.csv" });
        },*/
        /**
         * Nettoyer les tableaux&graphiques des résultats de la fonctionnalité 1.
         */
        fct1NettoyageResultats() {
            this.fct1.interne.resultats = null;
            this.fct1.interne.resultat1.resultatsTabImpactsDateDefanage.data = [];            
            this.fct1.interne.resultat1.resultatsTabSynthetique.columns = [];
            this.fct1.interne.resultat1.resultatsTabSynthetique.data = [];            
            this.fct1.interne.resultat_evol_variables.chartsVarInteret = [];
            this.fct1.interne.resultat_evol_variables.chartsVarInteretCalc = [];
            this.fct1.interne.resultat_evol_variables.chartsVarInteretMapp = [];
            this.fct1.interne.resultat_etat_des_lieux.chartsVarInteret = [];
            this.fct1.interne.resultat_etat_des_lieux.chartsVarInteretCalc = [];
            this.fct1.interne.resultat_evol_calibres.charts = [];
        },
        /**
         * Nettoyer les tableaux&graphiques des résultats de la fonctionnalité 2.
         */
        fct2NettoyageResultats() {
            this.fct2.interne.resultats = null;
            this.fct2.interne.resultat1.resultatsTab.data = [];  
            this.fct2.interne.resultat1.resultatsTabSynthetique.data = [];
            this.fct2.interne.resultat_evol_variables.chartsVarInteret = [];
            this.fct2.interne.resultat_evol_variables.chartsVarInteretCalc = [];
        }, 
    }
}
</script>

<style lang="scss">
@import "c3/c3";

.title:not(:last-child) {
    margin-top: 1.5rem;
}

.fct1_resultat1 {
    border: 1px solid #009900;
}

.texte_descriptif_resultat_app {
    font-style: italic;
    margin-bottom: 0.5rem;
}

.important {
    font-weight: bold;
    color: #ff0000;
}

input[type=number] {
    max-width: 8em;
    text-align: right;
}
</style>