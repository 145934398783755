<template>
	<div>
		<h1 class="title">Mes parcelles</h1>
		
		<b-table 
			:data="parcellesTableau.data" 
			:columns="parcellesTableau.columns" 
			:checked-rows.sync="parcellesTableau.checkedLignes"
			checkable 
			>
		</b-table>

		<b-button type="is-info is-light" @click="editParcelle" :disabled="1 !== parcellesTableau.checkedLignes.length">
			Editer parcelle sélectionnée
		</b-button>
		<b-button type="is-info is-light" @click="deleteParcelle" :disabled="1 !== parcellesTableau.checkedLignes.length">
			Supprimer parcelle sélectionnée
		</b-button>
		
		
		<h1 class="title" v-if="'create' == this.create_or_update">Ajout parcelle</h1>
		<h1 class="title" v-else-if="'update' == this.create_or_update">Modification parcelle</h1>
<form id="form_edit_parcelle" @submit.prevent="validationForm">
		<!--<div v-show="'Volonté du métier de masquer cette information' == 'Masque çà !'">
			Id parcelle :
			<span>{{ parcelleEdit.id }}</span>
		</div>-->

		Nom parcelle :
		<input
			type="text"
			v-model="parcelleEdit.nom"
			placeholder="ma belle parcelle"
		/>
		<div>
			<label>
				<div class="subtitle">Commune et département</div>
				<b-select v-model="saisieUtilisateur.dept_ville">
                    <option
                        v-for="commune in this.interne.refCommunes"
                        :key="commune.code_dept + '||' + commune.nom"
                        :value="commune.code_dept + '||' + commune.nom" 
						
                    >
                        {{ commune.nom + " (" + commune.code_dept + ")" }}
                    </option>
                </b-select>
			</label>
		</div>

		<div class="subtitle">Itinéraire technique</div>
        <itk-new ref="refItk"></itk-new>

        <div class="subtitle">Caractérisation sol</div>
        <type-sol ref="refSol"></type-sol>

		<!--<b-button type="is-info is-light" @click="remplissageDonneesUtilisateur">
			Remplissage de données tests dans les champs utilisateur
		</b-button>-->
		
		<div v-if="'create' == create_or_update">
			<b-button type="is-info is-light" native-type="submit">
				Créer cette parcelle
			</b-button>
		</div>
		<div v-else-if="'update' == create_or_update">
			<b-button type="is-info is-light" native-type="submit">
				Enregistrer cette parcelle
			</b-button>
			<b-button type="is-info is-light" @click="cancelUpdateParcelle">
				Annuler
			</b-button>
		</div>
	</form>
		<div>
		{{ interne.textePopup }}
		</div>
		<p v-if="errors.length">
			<b>SVP Corrigez les erreur(s) suivante(s) :</b>
			<ul>
				<li class="error" v-for="error in errors" :key="error.id">{{ error }}</li>
			</ul>
		</p>
		
		<footer class="footer">
		
		</footer>
  </div>
</template>

<script>

import axios from "axios";
import ItkNew from "@/components/ItkNew.vue";
import TypeSol from "@/components/TypeSol.vue";
import { constantes } from '@/utils';

const parcelleTemplateNew = {
	id: null,
	nom: "",
};
const parcelle2TemplateNew = {
	code_dept: null,
	nom_commune: null,
	fao56_activation: true,
};

export default {
	name: 'Parcelles',
	components: {
		ItkNew,
		//ItkList,
		TypeSol,
	},
	data() {
		return {
			errors: [],
			interne: {
				refCommunes: [],
				textePopup: "",
			},
			saisieUtilisateur: {
				dept_ville: null,
			},
			donneesEntreeTemplateVide: {
				parcelle: {
					itk: [],
					caracterisation_sol: [],
				}
			},
			parcellesTableau: {
				data: 
				[
				],
				columns: 
				[      
					// {
                    //     field: 'id',
                    //     label: 'ID',
                    // },
					{
                        field: 'nom',
                        label: 'Nom',
                    }                          
				],
				checkedLignes: [],           
			},
			parcelleEdit: JSON.parse(JSON.stringify(parcelleTemplateNew)),
			parcelle2Edit: JSON.parse(JSON.stringify(parcelle2TemplateNew)),
			create_or_update: 'create', // create | update
		};
	},
	computed: {
		URL_PASSERELLE: function () {
            return (process.env.VUE_APP_PASSERELLE_URL);
        },
	},
	watch: {
        "saisieUtilisateur.dept_ville": {
            handler: function (newValue) {
                this.parcelle2Edit.code_dept = (null == newValue) ? null : newValue.split("||")[0];
				this.parcelle2Edit.nom_commune = (null == newValue) ? null : newValue.split("||")[1];
            },
            deep: true,
        },
	},
    created() {
        
    },
    beforeMount() {
		this.readParcelles();

		axios
            .get(`${this.URL_PASSERELLE}/api/references/communes`)
            .then((response) => {
                if (200 == response.status) {
                    // Picardie, NPdC, Bourgogne
                    this.interne.refCommunes = response.data
                        // .filter((dataI) =>
                        //     [
                        //         "02", "60", "80", 
                        //         "59", "62",
                        //         "89", "58", "21", "71", "70", "25", "39", "90",
                            
                        //     ].includes(
                        //         dataI.code_dept.substr(0, 2)
                        //     )
                        // )
                        .map((dataI) => ({
                            code_dept: dataI.code_dept,
                            nom: dataI.nom,
                        }))
                        .sort((dataI) => ({
                            code_dept: dataI.code_dept,
                            nom: dataI.nom,
                        }));
                } else {
                    this.fct1.interne.texteChargementCalculs =
                        "Traitement terminé avec des erreurs (1)";
                }
            })
            .catch((error) => {
                console.error(error);
            });
	},
	mounted() {
		
	},
	methods: {
		validationForm() {
			this.errors = [];
			const profondeur_plantation = this.$refs.refItk.localItk.profondeur_plantation;

			if(profondeur_plantation < 5 || profondeur_plantation > 20) {
				this.errors.push(`Profondeur de plantation : doit être comprise entre 5 et 20 cm (valeur saisie : ${profondeur_plantation})`);
			}

			if (!this.errors.length) {
				// j'enregistre l'ajout/modif
				if('create' == this.create_or_update) {
					//this.interne.textePopup = 'on crée';
					this.createParcelle();
				}
				else if ('update' == this.create_or_update) {
					//this.interne.textePopup = 'on modifie';
					this.updateParcelle();
				}
				else {
					this.interne.textePopup = "Action inconnue";
				}
			}

			// A SAVOIR : checkValidity() : utilisable sur un élément HTML, pas sur une variable en v-model sur un élément HTML
		},
		initParcelle() {
			this.create_or_update = 'create';

			this.parcelleEdit = JSON.parse(JSON.stringify(parcelleTemplateNew));
			this.parcelle2Edit = JSON.parse(JSON.stringify(parcelle2TemplateNew));
			this.saisieUtilisateur.dept_ville = null;

			this.$refs.refItk.initItk();
			this.$refs.refSol.$refs.typeSolHorizon1.initTypesol();
			this.$refs.refSol.$refs.typeSolHorizon1.localSol.horizon = 1;
			this.$refs.refSol.$refs.typeSolHorizon2.initTypesol();
			this.$refs.refSol.$refs.typeSolHorizon2.localSol.horizon = 2;
			this.$refs.refSol.$refs.typeSolHorizon3.initTypesol();
			this.$refs.refSol.$refs.typeSolHorizon3.localSol.horizon = 3;
		},
		remplissageDonneesUtilisateur() {
			this.$refs.refSol.$refs.typeSolHorizon1.localSol = {
                horizon: 1,
                epaisseur: 30,
                triangle: '\\"AISNE\\"',
                texture: null,
                argile: 17,
                limon: 75.1,
                sable: 6,
                carbone_organique: 1.9,
                densite_apparente: 1.38,
                humidite_initiale: 0.5,
                humidite_saturation: null,
                humidite_capacite_au_champ: 0.2376,
                humidite_point_fletrissement: 0.0872,
            };
            this.$refs.refSol.$refs.typeSolHorizon2.localSol = {
                horizon: 2,
                epaisseur: 15,
                triangle: '\\"AISNE\\"',
                texture: null,
                argile: 21,
                limon: 74.7,
                sable: 4,
                carbone_organique: 0.3,
                densite_apparente: 1.6,
                humidite_initiale: 0.5,
                humidite_saturation: null,
                humidite_capacite_au_champ: 0.2208,
                humidite_point_fletrissement: 0.1023,
            };
            this.$refs.refSol.$refs.typeSolHorizon3.localSol = {
                horizon: 3,
                epaisseur: 55,
                triangle: '\\"AISNE\\"',
                texture: null,
                argile: 25,
                limon: 69.9,
                sable: 5,
                carbone_organique: 0.1,
                densite_apparente: 1.61,
                humidite_initiale: 0.5,
                humidite_saturation: null,
                humidite_capacite_au_champ: null,
                humidite_point_fletrissement: null,
            };
		},
		nettoyageDonneesParcelle() {

		},
		createParcelle() {
			const nomParcelle = this.parcelleEdit.nom;

			let JSONdata = JSON.parse(
                JSON.stringify(this.donneesEntreeTemplateVide)
            );
			
			let dataItk = this.$refs.refItk.localItk;
			let dataSolHorizon1 = JSON.parse(
                JSON.stringify(this.$refs.refSol.$refs.typeSolHorizon1.localSol)
            );
            let dataSolHorizon2 = JSON.parse(
                JSON.stringify(this.$refs.refSol.$refs.typeSolHorizon2.localSol)
            );
            let dataSolHorizon3 = JSON.parse(
                JSON.stringify(this.$refs.refSol.$refs.typeSolHorizon3.localSol)
            );
            let dataSol = [dataSolHorizon1, dataSolHorizon2, dataSolHorizon3];

			Object.assign(JSONdata, this.parcelleEdit);
			JSONdata.parcelle.parcelle = this.parcelle2Edit;
			JSONdata.parcelle.itk = dataItk;
            JSONdata.parcelle.caracterisation_sol = dataSol;

			axios
            .post(
				`${this.URL_PASSERELLE}/api/utilisateurs/parcelles`,
				JSONdata
			)
            .then((response) => {
                if (200 == response.status && !response.data.message) {
					this.interne.textePopup = `Parcelle "${nomParcelle}" crée`;
					this.initParcelle();
					this.readParcelles();
				} 
				else if (response.data.message) {
					this.interne.textePopup = `Traitement terminé avec des erreurs (1) (${response.data.message})`;
				} 
				else {
					this.interne.textePopup = "Traitement terminé avec des erreurs (1)";
				}
            })
            .catch((error) => {
                console.error(error);
            });
		},
		readParcelles() {
			axios
            .get(
				`${this.URL_PASSERELLE}/api/utilisateurs/parcelles`,
			)
            .then((response) => {
                if (200 == response.status && !response.data.message) {
					this.parcellesTableau.data = response.data;
					this.parcellesTableau.checkedLignes = [];
				} 
				else if (response.data.message) {
					this.interne.textePopup = `Traitement terminé avec des erreurs (1) (${response.data.message})`;
				} 
				else {
					this.interne.textePopup = "Traitement terminé avec des erreurs (1)";
				}
            })
            .catch((error) => {
                console.error(error);
            });
		},
		editParcelle() {
			const idParcelle = this.parcellesTableau.checkedLignes[0]?.id;

			axios
            .get(
				`${this.URL_PASSERELLE}/api/utilisateurs/parcelles/${idParcelle}`,
			)
            .then((response) => {
                if (200 == response.status && !response.data.message) {
					this.create_or_update = 'update';

					//this.parcelles.data = response.data;
					//console.log(response.data);
					const idParcelle = response.data.id;
					const nomParcelle = response.data.nom;
					const parcelle = response.data.parcelle;
					parcelle.itk.hauteur_butte = constantes.itk.hauteur_butte.value;
					
					this.parcelleEdit.id = idParcelle;
					this.parcelleEdit.nom = nomParcelle;
					this.parcelle2Edit = parcelle?.parcelle ?? JSON.parse(JSON.stringify(parcelle2TemplateNew));

					// if(undefined == parcelle?.parcelle) {
					// 	this.saisieUtilisateur.dept_ville = null;
					// }
					// else {
						this.saisieUtilisateur.dept_ville = this.parcelle2Edit.code_dept + '||' + this.parcelle2Edit.nom_commune;
					//}

					this.$refs.refSol.$refs.typeSolHorizon1.localSol = parcelle.caracterisation_sol[0];
					this.$refs.refSol.$refs.typeSolHorizon2.localSol = parcelle.caracterisation_sol[1];
					this.$refs.refSol.$refs.typeSolHorizon3.localSol = parcelle.caracterisation_sol[2];
					this.$refs.refItk.localItk = parcelle.itk;

					this.interne.textePopup = `Parcelle "${nomParcelle}" (${idParcelle}) chargée`;
				} 
				else if (response.data.message) {
					this.interne.textePopup = `Traitement terminé avec des erreurs (1) (${response.data.message})`;
				} 
				else {
					this.interne.textePopup = "Traitement terminé avec des erreurs (1)";
				}
            })
            .catch((error) => {
                console.error(error);
            });
		},
		updateParcelle() {
			const idParcelle = this.parcelleEdit.id;
			const nomParcelle = this.parcelleEdit.nom;

			let JSONdata = JSON.parse(
				JSON.stringify(this.donneesEntreeTemplateVide)
            );
			
			let dataItk = this.$refs.refItk.localItk;
			let dataSolHorizon1 = JSON.parse(
                JSON.stringify(this.$refs.refSol.$refs.typeSolHorizon1.localSol)
            );
            let dataSolHorizon2 = JSON.parse(
                JSON.stringify(this.$refs.refSol.$refs.typeSolHorizon2.localSol)
            );
            let dataSolHorizon3 = JSON.parse(
                JSON.stringify(this.$refs.refSol.$refs.typeSolHorizon3.localSol)
            );
            let dataSol = [dataSolHorizon1, dataSolHorizon2, dataSolHorizon3];

			Object.assign(JSONdata, this.parcelleEdit);
			JSONdata.parcelle.parcelle = this.parcelle2Edit;
			JSONdata.parcelle.itk = dataItk;
            JSONdata.parcelle.caracterisation_sol = dataSol;

			axios
            .put(
				`${this.URL_PASSERELLE}/api/utilisateurs/parcelles/${idParcelle}`,
				JSONdata,
			)
            .then((response) => {
                if (200 == response.status && !response.data.message) {
					this.interne.textePopup = `Parcelle "${nomParcelle}" (${idParcelle}) modifiée`;

					this.initParcelle();
					this.readParcelles();
				} 
				else if (response.data.message) {
					this.interne.textePopup = `Traitement terminé avec des erreurs (1) (${response.data.message})`;
				} 
				else {
					this.interne.textePopup = "Traitement terminé avec des erreurs (1)";
				}
            })
            .catch(() => {
                this.interne.textePopup = "Traitement terminé avec des erreurs (999)";
            });
		},
		cancelUpdateParcelle() {
			this.initParcelle();
		},
		deleteParcelle() {
			const idParcelle = this.parcellesTableau.checkedLignes[0]?.id;

			axios
            .delete(
				`${this.URL_PASSERELLE}/api/utilisateurs/parcelles/${idParcelle}`,
			)
            .then((response) => {
                if (200 == response.status && !response.data.message) {
					this.interne.textePopup = `Parcelle ${idParcelle} supprimée`;

					this.initParcelle();
					this.readParcelles();
				} 
				else if (response.data.message) {
					this.interne.textePopup = `Traitement terminé avec des erreurs (1) (${response.data.message})`;
				} 
				else {
					this.interne.textePopup = "Traitement terminé avec des erreurs (1)";
				}
            })
            .catch(() => {
                this.interne.textePopup = "Traitement terminé avec des erreurs (999)";
            });
		},
	}
}
</script>

<style lang="scss">
	.upperCaseFirstLetter {
		text-transform: capitalize;
	}

	.subtitle {
		padding-top: 0.9rem;
		margin-bottom: 0 !important;
	}

	.error {
		color: #ff0000;
		font-weight: bold;
	}
</style>