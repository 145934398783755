import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from '@/views/Home.vue';
import Simulation from '@/views/Simulation.vue';
import Parcelles from '@/views/Parcelles.vue';
import Error from '@/views/Error.vue';

import axios from "axios";

Vue.use(VueRouter);


export const router = new VueRouter({
    mode: "history",
    routes: [
        {
            path: "/",
            component: Home,
        },
        {
            path: "/parcelles",
            component: Parcelles,
            meta: {
                requiresAuth: true,
                authorizedRoles : ["pomod_admin", "pomod_standard"], // il s'agit de rôles de royaume
                authorizedResourceRoles: ["admin", "standard"], // il s'agit des rôles de la ressource POMOD_BROWSER
            }
        },
        {
            path: "/simulation",
            component: Simulation,
            meta: {
                requiresAuth: true,
                authorizedRoles : ["pomod_admin", "pomod_standard"],
                authorizedResourceRoles: ["admin", "standard"],
            }
        },
        {
            path: "/error",
            component: Error,
        }
        //{
            //path: "/userList",
            //component: userlist,
            //meta: {
            //    requiresAuth: true,
            //    authorizedRoles : ["admin_simeos"],
            //}
        //},
    ]
});



router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // We wait for Keycloak init, then we can call all methods safely
        while (!router.app.$keycloak.ready) {
            await sleep(160);
        }

        if (isAuthenticated()) { 
            if (userHasRoles(to)) {
                //console.log("AVANT router.app.$store : ", router.app.$store.state.utilisateurExisteEnBdd);
                // TODO en local : le store n'a pas de mémoire (en lien probable : refresh plusieurs fois la page pour récupérer l'utilisateur authentifié)
                if(true != router.app.$store.state.utilisateurExisteEnBdd) {
                    axios
                    .get(
                        `${process.env.VUE_APP_PASSERELLE_URL}/api/utilisateurs/sync-bdd`,
                        //`${this.URL_PASSERELLE}/api/utilisateurs/sync-bdd`,
                    )
                    .then((response) => {
                        if (200 == response.status && !response.data.message) {
                            //console.log(process.env.VUE_APP_PASSERELLE_URL,"avant : ", router.app.$store.state.utilisateurExisteEnBdd);
                            if(response.data.present || response.data.creation) {
                                router.app.$store.commit('setUtilisateurExisteEnBdd', true);
                            }
                            else {
                                router.app.$store.commit('setUtilisateurExisteEnBdd', false);
                            }
                            //console.log("APRES router.app.$store : ", router.app.$store.state.utilisateurExisteEnBdd);
                        } 
                        /*else if (response.data.message) {
                            this.fct1.interne.texteChargementCalculs = `Traitement terminé avec des erreurs (1) (${response.data.message})`;
                        } 
                        else {
                            this.fct1.interne.texteChargementCalculs = "Traitement terminé avec des erreurs (1)";
                        }*/
                        return next();
                    })
                    .catch((error) => {
                        console.error(error);
                    });
                    
                }
                else 
                return next();
            }
            else {
                /*return next({
                    path: '/error',
                    query: { redirect: to.fullPath }
                });*/
                window.location.replace('/error');
                //console.log("Droits insuffisants");
            }

            //window.location.replace('/');
        } else {
            // on redirige la 
            // car une déconnexion ne redirige pas vers la page de login (on reste sur la même page (même si elle était connectée))
            const loginUrl = router.app.$keycloak.createLoginUrl();
            window.location.replace(loginUrl);

            //console.log("Utilisateur non authentifié");
        }
    } else {
        next();
    }
})


function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
}


function isAuthenticated() {
    return router.app.$keycloak.authenticated;
}

function userHasRoles(to) {
    //to;
    //return true;

    let isAuthorized = false;

    if (to.matched.some(record => record.meta.authorizedRoles)) {
        if (isAuthorized) {
            return isAuthorized;
        }

        // ancienne méthode (30/07/2021) de test d'accès aux routes : rôle de royaume
        // to.meta.authorizedRoles.forEach(role => {
        //     //console.log(router.app.$keycloak);
        //     if (router.app.$keycloak.hasRealmRole(role)) {
        //         isAuthorized = true;
        //     }   
        // })

        // méthode de test d'accès aux routes : rôle de ressource (client)
        to.meta.authorizedResourceRoles.forEach(role => {
            if (router.app.$keycloak.hasResourceRole(role)) {
                isAuthorized = true;
            }
        });
    }

    return isAuthorized;
}


export default router;