<template>
    <div>
        <header>
            <div v-if="!keycloak_data.authenticated">

                <b-button
                    label="Connexion"
                    type="is-danger"
                    icon-left="login"
                    @click="$keycloak.login()"/>
            </div>
            <div v-else>
                <b-icon icon="account"></b-icon>
                utilisateur ({{keycloak_data.idTokenParsed.family_name}} {{keycloak_data.idTokenParsed.given_name}})
                <b-button
                    label="Déconnexion"
                    type="is-danger"
                    icon-left="logout"
                    @click="$keycloak.logoutFn"/>
            </div>

            <div>
                <div class="groupe-logos">
                    <img src="@/assets/img/logos/agt.svg" alt="AGro-Transfert Ressources et Territoires" style="height: 60px;" />
                    <img src="@/assets/img/logos/arvalis-institut-du-vegetal.png" alt="Arvalis" style="width: 100px;" />
                    <img src="@/assets/img/logos/comite-nord.jpg" alt="Comité Nord" style="height: 60px;" />
                    <img src="@/assets/img/logos/expandis.png" alt="Expandis" style="height: 60px;" />
                    <img src="@/assets/img/logos/uneal.png" alt="Unéal" style="height: 75px;" />
                    <img src="@/assets/img/logos/CA-NPDC.jpg" alt="Chambre d'Agriculture Nord Pas de Calais" style="height: 90px;" />                    
                    <img src="@/assets/img/logos/INRAE.jpg" alt="INRAE" style="height: 40px;" />
                    <img src="@/assets/img/logos/region-hdf.jpg" alt="Région Hauts de France" style="height: 40px;" />
                    <img src="@/assets/img/logos/ue.jpg" alt="Union Européenne" style="height: 62px;" />
                    <img src="@/assets/img/logos/feder.jpg" alt="FEDER" style="height: 60px;" />
                </div>
                

            </div>
        </header>

        <div class="menu">
            <b-button><router-link to="/parcelles">Parcelles</router-link></b-button> &nbsp;&nbsp;
            <b-button><router-link to="/simulation">Simulations</router-link></b-button>
        </div>
 
        <router-view></router-view>
    
        <footer>
            POMOD 
        </footer>

        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@mdi/font@5.8.55/css/materialdesignicons.min.css">
    </div>
</template>

<script>
import router from "@/router";
require("dotenv").config();

export default {
    name: "App",
    components: {
        
    },
    data: function () {
        return {
            keycloak_data: null,
        };
    },
    computed: {
        
    },
    watch: {

    },
    created() {
        
    },
    beforeMount() {
        //this.keycloak_data = this.$keycloak;
        this.keycloak_data = router.app.$keycloak;
    },
    mounted() {
        
    },
    methods: {

    },
};
</script>

<style lang="scss">

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}

.menu {
    background-color: #11aa11;
}

footer {
    bottom: 0;
    width: 100%;
    margin-top: 10px;
    height: 40px;
    background-color: #11aa11;
}

.groupe-logos {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap:wrap;
    row-gap:5px;
}

</style>
