<template>
    <span>
        <div>Phase : {{ stade_debut }} - {{ stade_fin }}</div>
        
        <label>
            <span>{{ constantIrrig.seuil_relatif.label }}</span>
            <!--{{ localIrrigation.seuil_relatif }} {{ constantIrrig.seuil_relatif.unite }}-->
            <input type="number" name="seuil_relatif" readonly="readonly" min=0 max=100 step=0.01 v-model.number="localIrrigation.seuil_relatif" @input="updateDataDebounced">
        </label>
        <label>
            <span>{{ constantIrrig.dose_min.label }}</span>
            <!--{{ localIrrigation.dose_min }} {{ constantIrrig.dose_min.unite }}-->
            <input type="number" name="dose_min" readonly="readonly" v-model.number="localIrrigation.dose_min" @keyup="updateDataDebounced"> mm
        </label>
        <label>
            <span>{{ constantIrrig.dose_max.label }}</span>
            <!--{{ localIrrigation.dose_max }} {{ constantIrrig.dose_max.unite }}-->
            <input type="number" name="dose_max" readonly="readonly" v-model.number="localIrrigation.dose_max" @keyup="updateDataDebounced"> mm
        </label>
        <label>
            <span>{{ constantIrrig.retour_min.label }}</span>
            <!--{{ localIrrigation.retour_min }} {{ constantIrrig.retour_min.unite }}-->
            <input type="number" name="retour_min" readonly="readonly" v-model.number="localIrrigation.retour_min" @keyup="updateDataDebounced"> j
        </label>
        <label>
            <span>{{ constantIrrig.retour_max.label }}</span>
            <!--{{ localIrrigation.retour_max }} {{ constantIrrig.retour_max.unite }}-->
            <input type="number" name="retour_max" readonly="readonly" v-model.number="localIrrigation.retour_max" @keyup="updateDataDebounced"> j
        </label>
        <label>
            <span>{{ constantIrrig.mise_en_reserve.label }}</span>
            <!--{{ localIrrigation.mise_en_reserve }} {{ constantIrrig.mise_en_reserve.unite }}-->
            <input type="number" name="mise_en_reserve" readonly="readonly" v-model.number="localIrrigation.mise_en_reserve" @keyup="updateDataDebounced"> mm
        </label>
    </span>
</template>

<script>
import { constantes } from '@/utils';
let _ = require('lodash');
//let idxPhaseRef;

/*function debounce (fn, delay) {
            var timeoutID = null
            return function () {
                clearTimeout(timeoutID)
                var args = arguments
                var that = this
                timeoutID = setTimeout(function () {
                fn.apply(that, args)
                }, delay)
            }
        }*/
/*
function throttle(callback, delay) {
    var last;
    var timer;
    return function () {
        var context = this;
        var now = +new Date();
        var args = arguments;
        if (last && now < last + delay) {
            // le délai n'est pas écoulé on reset le timer
            clearTimeout(timer);
            timer = setTimeout(function () {
                last = now;
                callback.apply(context, args);
            }, delay);
        } else {
            last = now;
            callback.apply(context, args);
        }
    };
}*/


export default {
    name: 'IrrigationModeleDecisionStrategie',
    props: {        
        stade_debut: {
            type: String,
            required: true,
        },
         stade_fin: {
            type: String,
            required: true,
        },
        localIrrigation: {
            type: Object,
            required: true,
        }
    },
    components: {
    },
    watch: {

    },
    data() {
        return {
            constantIrrig: constantes.irrigation,
            /*localIrrigation: {
                stade_debut: this.stade_debut,
                stade_fin: this.stade_fin,
                seuil_relatif: null,
            },*/
            //constantIrrig: constantes.irrigation,
        }
    },
    computed: {

    },
    methods: {
        /*debounce(func, wait, immediate) {
            var timeout;
            return function() {
                var context = this, args = arguments;
                var later = function() {
                    timeout = null;
                    if (!immediate) func.apply(context, args);
                };
                var callNow = immediate && !timeout;
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
                if (callNow) func.apply(context, args);
            };
        },*/
        /*debounce2 (fn, delay) {
            var timeoutID = null
            return function () {
                clearTimeout(timeoutID)
                var args = arguments
                var that = this
                timeoutID = setTimeout(function () {
                fn.apply(that, args)
                }, delay)
            }
        },*/
        /*delay(fn, ms) {
            let timer = 0;

            return function(...args) {
                clearTimeout(timer);
                timer = setTimeout(fn.bind(this, ...args), ms || 0);
            }
        },*/
        updateDataDebounced() { // TODO a revoir plus tard, car il envoie quand même autant d'appel que de keyup
            _.debounce(this.updateData, 500)();
            //this.updateData();
        },

        updateData() {
            this.$emit("updateData", this.localIrrigation);
        },
    },
    mounted() {
        /*let idxPhaseRef = constantes.irrigation.phase.findIndex(element => element.stade_debut == this.stade_debut);

        if(-1 === idxPhaseRef) {
            console.log("pas trouvé");
        }
        else {
            this.localIrrigation.seuil_relatif = constantes.irrigation.phase[idxPhaseRef].seuil_relatif_defaut;
            this.localIrrigation.dose_min = constantes.irrigation.phase[idxPhaseRef].dose_min_defaut;
            this.localIrrigation.dose_max = constantes.irrigation.phase[idxPhaseRef].dose_max_defaut;
            this.localIrrigation.retour_min = constantes.irrigation.phase[idxPhaseRef].retour_min_defaut;
            this.localIrrigation.retour_max = constantes.irrigation.phase[idxPhaseRef].retour_max_defaut;
            this.localIrrigation.mise_en_reserve = constantes.irrigation.phase[idxPhaseRef].mise_en_reserve_defaut;
        }
        
        this.$emit('loaded', this.localIrrigation);*/
    }
}
</script>

<style lang="scss">

</style>