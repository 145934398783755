<template>
  <div>
    <h1 class="title">Accueil</h1>
    <p class="description">
      L'outil d'aide à la décision POMOD permet de simuler la croissance d'une culture de pomme de terre.<br>
      Il comporte deux fonctionnalités : 
      <ul>
        <li>- la première permet d'anticiper la date de défanage</li>
        <li>- la seconde de juger la pertinence d'une prochaine irrigation</li>
      </ul>
    </p>
  </div>
</template>

<script>
//import router from "@/router";

export default {
    name: 'Home',
    components: {
        
    },
    data() {
        return {
        
        };
    },
    computed: {
        
    },
    created() {
        
    },
    beforeMount() {
    },
    mounted() {
    },
    methods: {

    }
}
</script>

<style lang="scss">

</style>