<template>
    <span>
        <!--<irrigation-modele-decision-strategie ref="phase.stade_debut" 
        v-for="phase in phases" :key="phase.stade_debut" 
        :stade_debut="phase.stade_debut" :stade_fin="phase.stade_fin" @loaded="loadedStrategie">
        
        </irrigation-modele-decision-strategie>-->
        <irrigation-modele-decision-strategie ref="phase.stade_debut" 
        v-for="phaseI in localIrrigation.phase" :key="phaseI.stade_debut" 
        :stade_debut="phaseI.stade_debut" :stade_fin="phaseI.stade_fin" :localIrrigation="phaseI">
        
        </irrigation-modele-decision-strategie>

        <br>
        <label>
            <span>{{ constantIrrig.volume_irrigation_total.label }}</span>
            <input type="number" name="volume_irrigation_max" readonly="readonly" step="0.01" v-model.number="constantIrrig.volume_irrigation_total.value"> {{ constantIrrig.volume_irrigation_total.unite }}
        </label>
        <label>
            <span>{{ constantIrrig.efficience_irrigation.label }}</span>
            <input type="number" name="efficience_irrigation" readonly="readonly" min="0" max="100" step="0.01" v-model.number="constantIrrig.efficience_irrigation.value">  {{ constantIrrig.efficience_irrigation.unite }}
        </label>
        <input type="hidden" name="stade_culture" v-model="localIrrigation.stade_culture">
    </span>
</template>

<script>
import { constantes } from '@/utils';
import IrrigationModeleDecisionStrategie from '@/components/IrrigationModeleDecisionStrategie.vue';

// TODO Variabiliser les intitulés de libellés
// TODO efficience_irrigation : numérique (entre 0 et 1), efficience de l’irrigation, prétraitement : affichage de 100 % au lieu de 1, posttraitement : 1 au lieu de 100%
export default {
    name: 'Irrigation',
    components: {
        IrrigationModeleDecisionStrategie,
    },
    data() {
        return {
            localIrrigation: {
                phase: [],
                volume_irrigation_max: 0,
                efficience_irrigation: 0,
                stade_culture: 'MAPP',
            },
            constantIrrig: constantes.irrigation,
            phases: constantes.irrigation.phase,
        }
    },
    computed: {

    },
    methods: {
        updateDataStrategie(data) {
            let idxPhaseRef = this.localIrrigation.phase.findIndex(element => element.stade_debut == data.stade_debut);

            if(-1 === idxPhaseRef) {
                console.log("pas trouvé");
            }
            else {
                this.localIrrigation.phase[idxPhaseRef].seuil_relatif = data.seuil_relatif;
            }
        },
        saveIrrigation() {
            this.localIrrigation.phase.push({
                seuil_relatif: this.$refs.plant_germe.localIrrigation.seuil_relatif,
            });          
        },

        loadedStrategie(data) {
            this.localIrrigation.phase.push(JSON.parse(JSON.stringify(data)));
        }
    },
    beforeMount() {
        this.phases.forEach(phase => {
            let idxPhaseRef = this.phases.findIndex(element => element.stade_debut == phase.stade_debut);

            if(-1 === idxPhaseRef) {
                console.log("pas trouvé");
            }
            else {
                this.localIrrigation.phase.push({});
                let indI = this.localIrrigation.phase.length - 1;
                this.localIrrigation.phase[indI].stade_debut = this.constantIrrig.phase[idxPhaseRef].stade_debut;
                this.localIrrigation.phase[indI].stade_fin = this.constantIrrig.phase[idxPhaseRef].stade_fin;
                this.localIrrigation.phase[indI].seuil_relatif = this.constantIrrig.phase[idxPhaseRef].seuil_relatif_defaut;
                this.localIrrigation.phase[indI].dose_min = this.constantIrrig.phase[idxPhaseRef].dose_min_defaut;
                this.localIrrigation.phase[indI].dose_max = this.constantIrrig.phase[idxPhaseRef].dose_max_defaut;
                this.localIrrigation.phase[indI].retour_min = this.constantIrrig.phase[idxPhaseRef].retour_min_defaut;
                this.localIrrigation.phase[indI].retour_max = this.constantIrrig.phase[idxPhaseRef].retour_max_defaut;
                this.localIrrigation.phase[indI].mise_en_reserve = this.constantIrrig.phase[idxPhaseRef].mise_en_reserve_defaut;
            }
        });

        this.localIrrigation.volume_irrigation_max = this.constantIrrig.volume_irrigation_total.value;
        this.localIrrigation.efficience_irrigation = this.constantIrrig.efficience_irrigation.value;
    }
}
</script>

<style lang="scss">

</style>