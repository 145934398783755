import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		app: 'POMOD',
		utilisateurExisteEnBdd: false,
	},
	getters: {
		copyright: state => {
			const currentYear = new Date().getFullYear()

			return `Copyright ${state.app} ${currentYear}`
		}
	},
	mutations: {
		setUtilisateurExisteEnBdd(state, val) {
			state.utilisateurExisteEnBdd = val;
		},
	},
	actions: {
		/*addToPomod: (context, amount) => {
			context.commit('unNomDeMethodeDansMutation', paramDeLaMethode);
		},*/
	},
	modules: {}
});
